import styles from "@/components/chatbot/helper.module.css";
import { DropdownClassName } from "@/components/constants/class-names";
import { ONBOARDING } from "@/components/constants/routes";
import { HelperWidgetZIndex } from "@/components/constants/zIndex";
import { Tooltip } from "@/components/utils/tooltip";
import { editorContextStore } from "@/contexts/editor-context";
import { getHighestUserStripeSubscriptionPlan } from "@/core/common/types/user-stripe-subscriptions";
import { classNames } from "@/core/utils/classname-utils";
import { QuestionMarkIcon } from "@radix-ui/react-icons";
import * as Popover from "@radix-ui/react-popover";
import React from "react";
import { useNavigate } from "react-router-dom";

const HelperWidgetTrigger = React.forwardRef(
  (
    {
      className,
      open,
      onOpenChange,
      ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
      open?: boolean;
      onOpenChange?: (value: boolean) => void;
    },
    forwardRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <Tooltip
        open={open}
        onOpenChange={onOpenChange}
        openDelay={300}
        triggerProps={{
          asChild: true,
        }}
        triggerChildren={
          <div
            {...props}
            ref={forwardRef}
            className="absolute bottom-[2rem] right-[2rem] rounded-full bg-black shadow-lg-center shadow-lime-500/20 p-3 text-zinc-300"
            style={{
              zIndex: HelperWidgetZIndex,
            }}
          >
            <QuestionMarkIcon />
          </div>
        }
        contentChildren="Help and resources"
        contentClassName="bg-black text-sm mx-2"
        contentProps={{
          side: "left",
        }}
      />
    );
  },
);

const DropdownItemClassName =
  "w-full pl-4 pr-8 py-2 text-left text-zinc-300 font-normal hover:text-lime-500 select-none cursor-pointer transition-colors focus:outline-none focus:bg-zinc-800/30 focus-visible:outline-none";

function DropdownLink({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) {
  return (
    <a
      {...props}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(DropdownItemClassName, className ?? "")}
    >
      {children}
    </a>
  );
}

export function HelperWidget() {
  const [open, setOpen] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const navigate = useNavigate();
  const userStripeSubscriptions = editorContextStore((state) => state.userStripeSubscriptions);
  const highestSubcriptionPlan = getHighestUserStripeSubscriptionPlan({
    userStripeSubscriptions,
  });

  return (
    <Popover.Root
      open={open}
      onOpenChange={(value) => {
        setTooltipOpen((open) => {
          if (!value) {
            return open;
          }
          if (!open) {
            return open;
          }
          return false;
        });
        setOpen(value);
      }}
    >
      <Popover.Trigger asChild>
        <HelperWidgetTrigger open={tooltipOpen} onOpenChange={setTooltipOpen} />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          sideOffset={6}
          className={classNames(
            DropdownClassName,
            styles.PopoverContent,
            "flex flex-col text-sm min-w-[180px] px-0 mx-2 bg-black",
          )}
          style={{
            zIndex: HelperWidgetZIndex,
          }}
        >
          <button
            className={DropdownItemClassName}
            onClick={(e) => {
              const { backend, setUserOnboardData } = editorContextStore.getState();
              const newOnboardData = {
                isProjectTutorialFinished: false,
                hasSeenCustomModelPopup: false,
                hasSeenDragAndDropCanvasPopup: false,
                hasSeenWelcomeScreen: false,
                hasSeenTeamWelcomeScreen: [],
              };
              setUserOnboardData(newOnboardData);
              backend?.setUserOnboardData({
                userOnboardData: newOnboardData,
              });
              setOpen(false);
              navigate(`/${ONBOARDING}`);
            }}
          >
            Restart onboarding
          </button>
          <DropdownLink
            href={`mailto:feedback@flair.ai?subject=${highestSubcriptionPlan}%20User%20-%20Feature%20Request`}
          >
            <button>Request a feature</button>
          </DropdownLink>
          <DropdownLink
            href={`mailto:support@flair.ai?subject=${highestSubcriptionPlan}%20User%20-%20Customer%20Support`}
          >
            Contact customer support
          </DropdownLink>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
