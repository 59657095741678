import { getTeamQuotasFromContext } from "@/core/utils/team-quota-utils";
import { editorContextStore, IEditorContext } from "contexts/editor-context";

export function getUserQuotasFromEditorContext(editorContextState: IEditorContext) {
  const { userQuotas } = editorContextState;

  return getTeamQuotasFromContext(editorContextState) || userQuotas;
}

export function getTeamOrUserQuotas() {
  return getUserQuotasFromEditorContext(editorContextStore.getState());
}
