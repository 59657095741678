import { PrimaryButtonClassName } from "@/components/constants/class-names";
import { editorContextStore } from "@/contexts/editor-context";
import { appRoleTypeDescriptions } from "@/core/common/types/team";
import { appRoleTypeToDisplayName } from "@/core/common/types/user-roles";
import { classNames } from "@/core/utils/classname-utils";
import { getAOrAn } from "@/core/utils/string-utils";
import { getCurrentTeamId } from "@/core/utils/team-utils";
import { doesUserHaveTeamFullPermission, getTeamUserRole } from "@/core/utils/user-role-utils";
import { Cross1Icon } from "@radix-ui/react-icons";
import React, { createContext } from "react";
import { MessageDialog, MessageDialogClose, MessageDialogProps } from "./message-dialog";

interface TeamOnboardingDialogContextType {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const TeamOnboardingDialogContext = createContext<TeamOnboardingDialogContextType>({
  open: false,
  setOpen: () => {},
});

export function useTeamOnboardingDialogContext() {
  return React.useContext(TeamOnboardingDialogContext);
}

interface TeamOnboardingDialogProviderProps {
  children: React.ReactNode;
}

export const TeamOnboardingDialogProvider: React.FC<TeamOnboardingDialogProviderProps> = ({
  children,
}) => {
  const currentTeamId = getCurrentTeamId();
  const userIsOwner = doesUserHaveTeamFullPermission(editorContextStore.getState());
  const userOnboardData = editorContextStore((state) => state.userOnboardData);
  const setUserOnboardData = editorContextStore((state) => state.setUserOnboardData);
  const backend = editorContextStore((state) => state.backend);
  const [open, setOpen] = React.useState<boolean>(() => {
    if (userIsOwner) {
      return false;
    }
    if (userOnboardData) {
      const hasSeenTeamWelcomeScreen = userOnboardData?.hasSeenTeamWelcomeScreen?.find(
        (teamId) => teamId === currentTeamId,
      );
      if (hasSeenTeamWelcomeScreen) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  });

  const handleSetOpen = (value: boolean) => {
    setOpen(value);
    if (!value) {
      let updatedUserOnboardData;
      if (userOnboardData?.hasSeenTeamWelcomeScreen) {
        updatedUserOnboardData = {
          ...userOnboardData,
          hasSeenTeamWelcomeScreen: [...userOnboardData.hasSeenTeamWelcomeScreen, currentTeamId],
        };
      } else {
        updatedUserOnboardData = {
          ...userOnboardData,
          hasSeenTeamWelcomeScreen: [currentTeamId],
        };
      }
      backend?.setUserOnboardData({
        userOnboardData: updatedUserOnboardData,
      });
      setUserOnboardData(updatedUserOnboardData);
    }
  };

  const value = { open, setOpen: handleSetOpen };

  return (
    <TeamOnboardingDialogContext.Provider value={value}>
      {children}
    </TeamOnboardingDialogContext.Provider>
  );
};

export const TeamOnboardingDialogContent = React.forwardRef(function TeamOnboardingDialogContent(
  { className = "", ...props }: React.HTMLAttributes<HTMLDivElement>,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const { setOpen } = useTeamOnboardingDialogContext();
  const userTeams = editorContextStore((state) => state.userTeams);
  const currentTeamId = getCurrentTeamId();
  const userRole = getTeamUserRole(editorContextStore.getState());
  const currentTeam = userTeams[currentTeamId];

  return (
    <div
      {...props}
      ref={forwardedRef}
      className="rounded-2xl w-[95vw] md:w-[600px] h-auto max-h-[95vh] max-w-[95vw] p-8 flex flex-col items-center bg-zinc-900"
    >
      <MessageDialogClose className="absolute right-4 top-4">
        <Cross1Icon className="text-zinc-500 hover:text-zinc-200 cursor-pointer transition-colors" />
      </MessageDialogClose>

      <div className="text-center gap-4 flex flex-col justify-center items-center">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-bold text-zinc-300">Welcome to {currentTeam?.name}</div>
          <div className="text-lg text-zinc-300">
            You have been added as {getAOrAn(appRoleTypeToDisplayName[userRole])}{" "}
            {appRoleTypeToDisplayName[userRole]}
          </div>
        </div>
        <div className="text-zinc-500">{appRoleTypeDescriptions[userRole]}</div>
        <button
          className={classNames(PrimaryButtonClassName, "w-fit mt-4")}
          onClick={() => setOpen(false)}
        >
          Get Started
        </button>
      </div>
    </div>
  );
});

export function TeamOnboardingDialog(props: MessageDialogProps) {
  const { open, setOpen } = useTeamOnboardingDialogContext();

  return (
    <MessageDialog
      {...props}
      open={open}
      onOpenChange={setOpen}
      contentChildren={<TeamOnboardingDialogContent />}
    />
  );
}
