import { AppUserQuotas, Optional, UserProject, UserProjectType } from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import { debugLog } from "@/core/utils/print-utilts";
import { canUserCreateNewProject } from "@/core/utils/quota-utils";
import { isValidFirebaseStoragePath, isValidHttpsUrl } from "@/core/utils/string-utils";
import { getTeamOrUserQuotas } from "@/hooks/use-user-quotas";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { DotsVerticalIcon, Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import {
  CardClassName,
  CardInactiveClassName,
  DashboardTitleClassName,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { NEW_PROJECT } from "components/constants/routes";
import { FloatTagZIndex } from "components/constants/zIndex";
import { TemplateCarousel } from "components/panels/panel-items/components/TemplateCarousel";
import { Tooltip } from "components/utils/tooltip";
import { editorContextStore } from "contexts/editor-context";
import { FilePlus2, Rocket, Shirt } from "lucide-react";
import React from "react";
import { Link, LinkProps, useNavigate } from "react-router-dom";
import { UserProjectTypeDefaultThumbnailUrl } from "../constants/user-project-type-styles";
import styles from "./dashboard.module.css";

const projectGridClassName = `${styles.DashboardGrid}`;

const ASPECT_RATIO = 4 / 3;

function ProjectCardContainer({
  children,
  to,
  ...props
}: Optional<LinkProps, "to"> &
  React.RefAttributes<HTMLAnchorElement> & {
    children?: React.ReactNode;
  }) {
  return to ? (
    <Link {...props} to={to}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
}

function DeleteProjectAlert({
  open = true,
  onOpenChange,
  onDelete,
}: {
  open?: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onDelete: () => void;
}) {
  return (
    <AlertDialogPrimitive.Root open={open} onOpenChange={onOpenChange}>
      <AlertDialogPrimitive.Portal>
        <AlertDialogPrimitive.Overlay className={styles.AlertDialogOverlay} />
        <AlertDialogPrimitive.Content
          className="absolute w-full h-full top-0 flex justify-center items-center text-zinc-300"
          onClick={() => {
            onOpenChange(false);
          }}
        >
          <div
            className="flex flex-col px-4 py-4 bg-zinc-900 rounded-md border border-zinc-800 shadow-lg-center shadow-zinc-300/10"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <AlertDialogPrimitive.Title className="font-medium text-zinc-500">
              Are you sure to delete this project?
            </AlertDialogPrimitive.Title>
            <div className="flex flex-row mt-4 justify-end items-center">
              <AlertDialogPrimitive.Cancel
                className={`ml-4 px-3 py-1 rounded-md font-medium ${SecondaryButtonClassNameInactive}`}
              >
                Cancel
              </AlertDialogPrimitive.Cancel>
              <AlertDialogPrimitive.Action
                className="ml-4 px-3 py-1 rounded-md font-medium bg-red-500 hover:bg-red-600 text-red-100 focus-visible:outline-red-600"
                onClick={onDelete}
              >
                Delete
              </AlertDialogPrimitive.Action>
            </div>
          </div>
        </AlertDialogPrimitive.Content>
      </AlertDialogPrimitive.Portal>
    </AlertDialogPrimitive.Root>
  );
}

function ProjectCardMenu({
  setIsEditingName,
  onDelete,
}: {
  setIsEditingName: () => void;
  onDelete: () => void;
}) {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <>
      <DropdownMenu.Root open={isOpen} onOpenChange={setOpen}>
        <DropdownMenu.Trigger asChild>
          <div className="flex-none flex justify-center items-center rounded-full bg-zinc-800 hover:text-lime-500 w-7 h-7">
            <DotsVerticalIcon />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          className="bg-zinc-900 border border-zinc-800 text-sm text-slate-700 rounded-lg shadow-md"
          style={{
            zIndex: FloatTagZIndex,
          }}
        >
          <DropdownMenu.Item
            className={`${styles.dropdownItem} ${styles.dropdownSelectable} text-zinc-300 hover:bg-zinc-800`}
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
              setIsEditingName();
            }}
          >
            <Pencil1Icon />
            <span className="mx-2">Rename</span>
          </DropdownMenu.Item>
          <DropdownMenu.Item
            className={`${styles.dropdownItem} ${styles.dropdownSelectable} text-zinc-300 hover:bg-zinc-800`}
            onClick={(e) => {
              e.preventDefault();
              onDelete?.();
              setOpen(false);
            }}
          >
            <TrashIcon className="text-zinc-300" />
            <span className="mx-2">Delete</span>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
}

function ProjectCard({
  id,
  name = "",
  thumbnail = "",
  link,
  projectType = UserProjectType.ProductPhotography,
  onNameChange,
  onDelete,
}: {
  id: string;
  name: string;
  thumbnail?: string;
  link?: string;
  projectType?: UserProjectType;
  onNameChange: (newName: string) => void;
  onDelete: () => void;
}) {
  const [filename, setFilename] = React.useState(name || "");
  const [isEditingName, setIsEditingName] = React.useState(false);
  const [thumbnailUrl, setThumbnailUrl] = React.useState<string>();
  const textInputRef = React.useRef<HTMLInputElement | null>(null);
  const isEscapeRef = React.useRef(false);
  const storageManager = editorContextStore((state) => state.storageManager);
  React.useEffect(() => {
    if (!storageManager || !thumbnail) {
      return;
    }
    debugLog("Getting thumbnail url for: ", thumbnail);
    if (typeof thumbnail === "string") {
      if (isValidHttpsUrl(thumbnail)) {
        debugLog("Setting thumbnail url to: ", thumbnail);
        setThumbnailUrl(thumbnail);
      } else if (isValidFirebaseStoragePath(thumbnail)) {
        debugLog("Getting download url from storage path: ", thumbnail);
        storageManager
          .getDownloadUrlFromStoragePath(thumbnail)
          .then((url) => {
            if (url) {
              debugLog("Setting thumbnail url to: ", url);
              setThumbnailUrl(url);
            }
          })
          .catch(console.error);
      }
    }
  }, [thumbnail, storageManager]);

  React.useEffect(() => {
    if (isEditingName) {
      textInputRef?.current?.focus();
      textInputRef?.current?.select?.();
    } else if (!isEscapeRef.current) {
      onNameChange?.(filename);
    } else if (isEscapeRef.current) {
      setFilename(name);
    }
    isEscapeRef.current = false;
  }, [isEditingName, filename, name, onNameChange]);

  return (
    <ProjectCardContainer
      to={isEditingName ? undefined : link}
      state={{
        projectType: projectType || UserProjectType.ProductPhotography,
      }}
    >
      <div className={`${CardClassName} relative`}>
        <AspectRatio.Root
          ratio={ASPECT_RATIO}
          className="pointer-events-none select-none bg-cover bg-center"
          style={{
            backgroundImage: thumbnailUrl
              ? `url(${thumbnailUrl})`
              : `url(${UserProjectTypeDefaultThumbnailUrl[projectType]})`,
          }}
        ></AspectRatio.Root>
        <div className="flex flex-row items-center px-3 py-3">
          <div className="flex-1 mr-2 truncate">
            {isEditingName ? (
              <input
                ref={textInputRef}
                type="text"
                name="filename"
                value={filename}
                className="w-full outline outline-1 rounded-sm focus:outline-zinc-800 bg-zinc-900 text-zinc-300"
                onChange={(e) => {
                  setFilename(e.currentTarget?.value || "");
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setFilename(e.currentTarget?.value || "");
                    setIsEditingName(false);
                  } else if (e.key === "Escape") {
                    setIsEditingName(false);
                    isEscapeRef.current = true;
                  }
                }}
                onBlur={() => setIsEditingName(false)}
              />
            ) : (
              <span>{filename}</span>
            )}
          </div>
          <ProjectCardMenu
            setIsEditingName={() => {
              setIsEditingName(true);
            }}
            onDelete={onDelete}
          />
        </div>
      </div>
    </ProjectCardContainer>
  );
}

function CreateNewTryOnProjectCard({
  onClick,
  disabled = false,
  userQuotas,
}: {
  onClick: () => void;
  disabled?: boolean;
  userQuotas?: AppUserQuotas | null;
}) {
  return (
    <Tooltip
      triggerProps={{
        asChild: true,
      }}
      triggerChildren={
        <div
          id="create-new-tryon-project-card"
          className={classNames(
            disabled ? CardInactiveClassName : CardClassName,
            "p-3 flex flex-row items-center justify-start group transition-colors",
            disabled
              ? "cursor-not-allowed text-zinc-500"
              : "cursor-pointer hover:border-teal-700 hover:shadow-teal-500/5",
          )}
          onClick={disabled ? undefined : onClick}
        >
          <div
            className={classNames(
              "p-1.5 bg-emerald-900/50 rounded transition-colors",
              disabled
                ? "text-emerald-800"
                : "text-emerald-300 group-hover:bg-emerald-800 group-hover:text-emerald-100",
            )}
          >
            <Shirt size={18} />
          </div>
          <div
            className={classNames(
              "min-w-0 px-3 truncate transition-colors",
              disabled ? "text-zinc-700" : "text-zinc-300 group-hover:text-zinc-100",
            )}
          >
            Create Fashion Project
          </div>
        </div>
      }
      contentProps={{
        align: "start",
        sideOffset: 8,
      }}
      contentChildren={
        disabled
          ? `Max ${userQuotas?.maxNumProjects ?? 0} projects reached`
          : "Create fashion project."
      }
    />
  );
}

function ExploreTemplatesCard({ onClick }: { onClick: () => void }) {
  const colorsUsed = {
    text: "text-orange-500",
    textDisabled: "text-orange-300 group-hover:text-orange-100`",
    bg: `bg-orange-900/50`,
    hoverBorder: `hover:border-orange-700`,
    hoverShadow: `hover:shadow-orange-500/5`,
  };
  return (
    <Tooltip
      triggerProps={{
        asChild: true,
      }}
      contentProps={{
        align: "start",
        sideOffset: 8,
      }}
      contentChildren="Explore existing templates for new projects"
      triggerChildren={
        <div>
          <div
            id="explore-workflows-card"
            className={classNames(
              CardClassName,
              "p-3 flex flex-row items-center justify-start group transition-colors",
              `cursor-pointer ${colorsUsed.hoverBorder} ${colorsUsed.hoverShadow}`,
            )}
            onClick={onClick}
          >
            <div
              className={classNames(
                `p-1.5 ${colorsUsed.bg} rounded transition-colors`,
                `${colorsUsed.text} group-hover:bg-${colorsUsed}-800 group-hover:text-${colorsUsed}-100`,
              )}
            >
              {<Rocket size={18} />}
            </div>
            <div
              className={classNames(
                "min-w-0 px-3 truncate transition-colors",
                "text-zinc-300 group-hover:text-zinc-100",
              )}
            >
              Explore Templates
            </div>
          </div>
        </div>
      }
    />
  );
}

function CreateNewProjectCard({
  onClick,
  disabled = false,
  userQuotas,
}: {
  onClick: () => void;
  disabled?: boolean;
  userQuotas?: AppUserQuotas | null;
}) {
  const colorsUsed = {
    text: "text-lime-500",
    textDisabled: "text-lime-300 group-hover:text-lime-100`",
    bg: `bg-lime-900/50`,
    hoverBorder: `hover:border-lime-700`,
    hoverShadow: `hover:shadow-lime-500/5`,
  };
  return (
    <Tooltip
      triggerProps={{
        asChild: true,
      }}
      triggerChildren={
        <div
          id="create-new-project-card"
          className={classNames(
            disabled ? CardInactiveClassName : CardClassName,
            "p-3 flex flex-row items-center justify-start group transition-colors",
            disabled
              ? "cursor-not-allowed text-zinc-500"
              : `cursor-pointer ${colorsUsed.hoverBorder} ${colorsUsed.hoverShadow}`,
          )}
          onClick={disabled ? undefined : onClick}
        >
          <div
            className={classNames(
              `p-1.5 ${colorsUsed.bg} rounded transition-colors`,
              disabled
                ? `${colorsUsed.textDisabled}`
                : `${colorsUsed.text} group-hover:bg-${colorsUsed}-800 group-hover:text-${colorsUsed}-100`,
            )}
          >
            <FilePlus2 size={18} />
          </div>
          <div
            className={classNames(
              "min-w-0 px-3 truncate transition-colors",
              disabled ? "text-zinc-500" : "text-zinc-300 group-hover:text-zinc-100",
            )}
          >
            Create New Project
          </div>
        </div>
      }
      contentProps={{
        align: "start",
        sideOffset: 8,
      }}
      contentChildren={
        disabled
          ? `Max ${userQuotas?.maxNumProjects ?? 0} projects reached`
          : `Create product photography project.`
      }
    />
  );
}

export function useCreateProjectEffect() {
  const userQuotas = getTeamOrUserQuotas();

  const canCreateNewProject = React.useMemo(
    () => canUserCreateNewProject(userQuotas),
    [userQuotas],
  );

  return {
    userQuotas,
    canCreateNewProject,
  };
}

function sortByTimeModified(a: UserProject, b: UserProject) {
  try {
    const aTimeModified = a.timeModified;
    if (!aTimeModified) {
      return 1; // b before a
    }
    const bTimeModified = b.timeModified;
    if (!bTimeModified) {
      return -1; // a before b
    }
    return bTimeModified.toDate().getTime() - aTimeModified.toDate().getTime();
  } catch (error) {
    console.warn("Error sorting by time modified: ", error);
    return 1;
  }
}

export const DashboardProjects = React.memo(function DashboardProjects({
  visible = true,
}: {
  visible?: boolean;
}) {
  const [projects, setProjects] = React.useState<Record<string, UserProject>>({});
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const currentProjectIdRef = React.useRef<string>();
  const user = editorContextStore((state) => state.user);
  const backend = editorContextStore((state) => state.backend);

  const navigate = useNavigate();

  const { canCreateNewProject } = useCreateProjectEffect();

  React.useEffect(() => {
    try {
      if (user) {
        return backend?.onUserProjectsUpdate(user.uid, (projects) => {
          setProjects(projects);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [backend, user, navigate]);

  return (
    <div className={classNames(visible ? "flex flex-col" : "hidden", "p-4")}>
      <div className={classNames(styles.DashboardButtonsGrid)}>
        {/* <ExploreTemplatesCard
          onClick={() => {
            setDashboardType("templates");
            navigate(`/templates`, { replace: true });
            trackTemplateAction("ProjectDashboardCardClick");
          }}
        /> */}
        <CreateNewProjectCard
          disabled={!canCreateNewProject}
          onClick={() => {
            navigate(`/${NEW_PROJECT}`, { replace: true });
          }}
        />
        {/* <CreateNewTryOnProjectCard
                    disabled={!canCreateNewProject}
                    onClick={() => {
                        navigate(`/${NEW_TRYON_PROJECT}`, { replace: true });
                    }}
                /> */}
      </div>
      <div className={classNames(DashboardTitleClassName, "my-4")}>Drag & Drop Templates</div>
      <TemplateCarousel />
      <div className={classNames(DashboardTitleClassName, "my-4")}>Recent Projects</div>
      <div className={projectGridClassName}>
        {Object.values(projects)
          ?.sort(sortByTimeModified)
          ?.map(
            (project) =>
              project?.id &&
              !projects.isDeleted && (
                <ProjectCard
                  key={project.id}
                  id={project.id}
                  name={project.displayName}
                  thumbnail={project.thumbnail}
                  projectType={project.projectType}
                  link={`/projects/${project.id}`}
                  onNameChange={(newName) => {
                    if (newName !== project.displayName) {
                      backend?.setProjectDisplayName(project.id, newName);
                    }
                  }}
                  onDelete={() => {
                    setIsAlertOpen(true);
                    debugLog("Set alert box to be open");
                    currentProjectIdRef.current = project.id;
                  }}
                />
              ),
          )}
        <div className="flex-auto min-w-0" />
      </div>
      <DeleteProjectAlert
        open={isAlertOpen}
        onOpenChange={setIsAlertOpen}
        onDelete={() => {
          const projectId = currentProjectIdRef.current;
          if (projectId) {
            setProjects((projects) => {
              delete projects[projectId];
              return projects;
            });
            backend?.deleteProject(projectId);
          }
        }}
      />
    </div>
  );
});
