import { Tooltip } from "@/components-mobile/utils/tooltip";
import {
  CustomModelPredictionInputBackendType,
  CustomModelPredictionItem,
  CustomModelSetPromptEditorStateEventHandler,
  getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput,
} from "@/core/common/types";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { getPromptStateFromText } from "@/core/utils/text-utils";
import { sortByTimeModified } from "@/core/utils/time-utils";
import { preprocessImageUrl } from "@/core/utils/url-utils";
import { doesUserHaveTeamFullPermission } from "@/core/utils/user-role-utils";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import {
  OutputItemToolbarButtonClassName,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { downloadUrl } from "components/utils/data";
import { ImageComponent } from "components/utils/image";
import { openAnimateVideoWindow } from "components/video/open-animate-image-window";
import { editorContextStore } from "contexts/editor-context";
import { formatDistanceToNow } from "date-fns";
import { useComponentSize } from "hooks/use-component-size";
import { clamp } from "lodash";
import { Download, SquarePen } from "lucide-react";
import React, { useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { ImageToVideoIcon } from "../video/image-to-video-icon";
import {
  CustomModelImageEditorDialog,
  CustomModelImageEditorDialogTrigger,
} from "./custom-model-image-editor";
import {
  CustomModelImageEditorMode,
  CustomModelImageEditorProps,
  useCustomModelImageEditorContext,
  useSetCustomModelImageEditorActiveModeCallback,
} from "./custom-model-image-editor-context";
import { getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState } from "./custom-model-mention-plugin";
import { useCustomModelPlayground } from "./custom-model-playground-context";

/* @tw */
const frostedGlassClassName =
  "text-sm text-zinc-300 backdrop-blur-sm hover:backdrop-blur-md bg-zinc-800/50 hover:bg-lime-500 active:bg-lime-700 border border-zinc-100/10 hover:border-lime-500 hover:text-zinc-900 truncate transition-all";

interface CustomModelPredictionImageProps extends CustomModelImageEditorProps {
  width: number;
  height: number;
  imageUrl: string;
}

function getImageAspectRatio({ width, height }: { width: number; height: number }) {
  try {
    const ratio = (width ?? 1) / (height ?? 1);
    return clamp(ratio, 1 / 5, 5);
  } catch (error) {
    debugError("Error getting image aspect ratio: ", error);
    return 1;
  }
}

function CustomModelPredictionImage({
  width,
  height,
  imageUrl,
  ...props
}: CustomModelPredictionImageProps & {
  prediction: CustomModelPredictionItem;
}) {
  const { prediction, imageIndex: index = 0 } = props;

  const { setPrediction, setWidth, setHeight, setImageIndex, setImageUrl } =
    useCustomModelImageEditorContext();

  const setMode = useSetCustomModelImageEditorActiveModeCallback();

  const handleOpenImageEditor = React.useCallback(() => {
    setPrediction(prediction);
    setWidth(width);
    setHeight(height);
    setMode(CustomModelImageEditorMode.Default);
    setImageIndex(index);
    setImageUrl(imageUrl);
  }, [
    setPrediction,
    setWidth,
    setHeight,
    setMode,
    setImageIndex,
    setImageUrl,
    width,
    height,
    index,
    prediction,
    imageUrl,
  ]);

  const ratio = React.useMemo(() => getImageAspectRatio({ width, height }), [width, height]);
  const userIsOwner = doesUserHaveTeamFullPermission(editorContextStore.getState());

  const [elementRef, inView] = useInView();

  const displayImageUrl = useMemo(
    () => (inView ? preprocessImageUrl(imageUrl) : ""),
    [inView, imageUrl],
  );

  return (
    <CustomModelImageEditorDialogTrigger
      asChild
      onClick={() => {
        handleOpenImageEditor();
      }}
    >
      <AspectRatio.Root
        ref={elementRef}
        ratio={ratio}
        className={classNames(
          "group/prediction relative rounded bg-contain hover:outline hover:outline-1 hover:outline-lime-500 overflow-hidden",
        )}
      >
        <ImageComponent
          className="pointer-events-none group-hover/prediction:scale-110 duration-300 transition-all w-full h-full object-contain"
          src={displayImageUrl}
        />
        <div
          className={classNames(
            "absolute top-0 left-0 p-2 w-full h-full flex flex-col items-stretch",
            "opacity-0 group-hover/prediction:opacity-100 transition-opacity",
            "pointer-events-none group-hover/prediction:pointer-events-auto",
          )}
        >
          <div className="absolute right-0 bottom-0 m-4 flex flex-row items-center justify-center gap-2">
            <Tooltip
              triggerProps={{
                asChild: true,
              }}
              triggerChildren={
                <div
                  className={OutputItemToolbarButtonClassName}
                  onClick={() => {
                    handleOpenImageEditor();
                  }}
                >
                  <SquarePen size={18} />
                </div>
              }
              contentChildren={<div>Edit Image</div>}
            />
            {userIsOwner && (
              <Tooltip
                triggerProps={{
                  asChild: true,
                }}
                triggerChildren={
                  <div
                    className={OutputItemToolbarButtonClassName}
                    onClick={() => {
                      openAnimateVideoWindow({
                        width,
                        height,
                        imageUrl,
                      });
                    }}
                  >
                    <ImageToVideoIcon size={18} />
                  </div>
                }
                contentChildren={<div>Animate image</div>}
              />
            )}
            <Tooltip
              triggerProps={{
                asChild: true,
              }}
              triggerChildren={
                <div
                  className={OutputItemToolbarButtonClassName}
                  onClick={() => {
                    const url = imageUrl;

                    debugLog(`Download image from url ${url}`);

                    downloadUrl(preprocessImageUrl(imageUrl), `output-${prediction.id}-${index}`);
                  }}
                >
                  <Download size={18} />
                </div>
              }
              contentChildren={<div>Download image</div>}
            />
          </div>
        </div>
      </AspectRatio.Root>
    </CustomModelImageEditorDialogTrigger>
  );
}

const predictionBackendTypeToTag: Record<CustomModelPredictionInputBackendType, string> = {
  [CustomModelPredictionInputBackendType.Fal]: "Generated result",
  [CustomModelPredictionInputBackendType.SelfHost]: "Generated result",
  [CustomModelPredictionInputBackendType.FixDetail]: "Fix details result",
  [CustomModelPredictionInputBackendType.RegenerateHuman]: "Regenrate human result",
  [CustomModelPredictionInputBackendType.UpscaleCreative]: "Upscale creative result",
  [CustomModelPredictionInputBackendType.ClarityUpscale]: "Upscale creative result",
  [CustomModelPredictionInputBackendType.GenerateVariations]: "Generate variations result",
  [CustomModelPredictionInputBackendType.InContextVariations]: "Generate variations result",
  [CustomModelPredictionInputBackendType.MultiStageGeneration]: "Generated result",
};

export function CustomModelPredictionTypeTag({
  prediction,
}: {
  prediction: CustomModelPredictionItem;
}) {
  return (
    <span className="flex-1 truncate">
      {
        predictionBackendTypeToTag[
          prediction.input?.backendType ?? CustomModelPredictionInputBackendType.Fal
        ]
      }
    </span>
  );
}

export function CustomModelPrediction({ prediction }: { prediction: CustomModelPredictionItem }) {
  const backend = editorContextStore((state) => state.backend);
  const storageManager = editorContextStore((state) => state.storageManager);
  const eventEmitter = editorContextStore((state) => state.eventEmitter);
  const [predictionImageUrls, setPredictionImageUrls] = React.useState<string[]>([]);
  const { setApiState } = useCustomModelPlayground();

  const input = React.useMemo(
    () => getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction.input),
    [prediction],
  );

  React.useEffect(() => {
    setPredictionImageUrls(Array(input?.num_outputs ?? 0).map(() => ""));

    if (!storageManager) {
      return;
    }

    const output = prediction?.output;

    if (!Array.isArray(output)) {
      return;
    }

    Promise.all(
      output.map((path) =>
        Assets.loadAssetFromPath({
          path,
          storageManager,
        }),
      ),
    ).then((imageUrls) => {
      const validImageUrls = imageUrls.filter(Boolean) as string[];

      setPredictionImageUrls(validImageUrls);
    });
  }, [storageManager, input, prediction?.output]);

  const timeAgo = React.useMemo(() => {
    const time = prediction.timeModified || prediction.timeCreated;

    if (!time) {
      return;
    }

    return formatDistanceToNow(time.toDate(), {
      addSuffix: true,
    });
  }, [prediction]);

  const [width, height] = React.useMemo(() => [input.width, input.height], [input]);
  const predictionInput = React.useMemo(
    () => getCustomModelPredictionInputSelfHostFromCustomModelPredictionInput(prediction.input),
    [prediction.input],
  );

  const prompt = React.useMemo(() => {
    const promptJson = predictionInput.promptJson;

    try {
      if (!promptJson) {
        return predictionInput.prompt;
      }

      const { text } = getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
        promptEditorState: JSON.parse(promptJson),
        replaceWithText: true,
      });

      return text;
    } catch (error) {
      debugError(`Cannot load prediction prompt json `, promptJson, "\n", error);
    }

    return predictionInput.prompt;
  }, [predictionInput]);

  const applyPredictionPrompt = React.useCallback(() => {
    const promptJson = predictionInput.promptJson;

    const promptEditorStateJson = promptJson || getPromptStateFromText(predictionInput.prompt);

    const promptEditorState = getCustomModelPlaygroundPromptEditorStateFromSerializedEditorState({
      promptEditorState: JSON.parse(promptJson || getPromptStateFromText(predictionInput.prompt)),
    });

    eventEmitter.emit<CustomModelSetPromptEditorStateEventHandler>(
      "custom-model:set-prompt-editor-state",
      {
        promptEditorStateJson,
      },
    );

    debugLog("Prompt editor state: ", promptEditorState);

    setApiState((prevApiState) => ({
      ...prevApiState,
      promptEditorState,
    }));
  }, [predictionInput, eventEmitter, setApiState]);

  return (
    <div
      className={classNames(
        SecondaryButtonClassNameInactive,
        "group h-auto max-w-full p-2 flex flex-col items-stretch gap-4 rounded-xl border border-zinc-800 shadow-xl active:border-lime-700 hover:border-zinc-600 hover:shadow-lg",
      )}
      onClick={() => {
        applyPredictionPrompt();
      }}
    >
      {predictionImageUrls.filter(Boolean).length > 0 && (
        <div className="h-fit grid gap-2 grid-cols-1">
          {predictionImageUrls.map((imageUrl, index) => (
            <CustomModelPredictionImage
              key={index}
              imageIndex={index}
              width={width}
              height={height}
              imageUrl={imageUrl}
              prediction={prediction}
            />
          ))}
        </div>
      )}
      <div className="flex flex-row truncate text-sm text-zinc-300 group-hover:text-lime-500">
        <CustomModelPredictionTypeTag prediction={prediction} />
        <span>{timeAgo}</span>
      </div>
      <div
        className={classNames(
          "text-sm px-2 py-1 bg-zinc-800/50 text-zinc-500 group-hover:text-zinc-300 rounded border border-zinc-800 transition-colors",
        )}
      >
        {prompt}
      </div>
    </div>
  );
}

export function CustomModelPredictions() {
  const customModelPredictions = editorContextStore((state) => state.customModelPredictions);

  const predictions = React.useMemo(() => {
    return Object.values(customModelPredictions).sort(sortByTimeModified);
  }, [customModelPredictions]);

  const [containerSize, containerRef] = useComponentSize<HTMLDivElement>();

  const { columns, numColumns } = React.useMemo(() => {
    const width = containerSize.width;

    const numColumns = width > 1024 ? 3 : width > 640 ? 2 : 1;

    const columns = Array.from(Array(numColumns).keys()).map((columnIndex) => {
      return predictions.filter((_, index) => index % numColumns === columnIndex);
    });

    return {
      columns,
      numColumns,
    };
  }, [containerSize.width, predictions]);

  return (
    <CustomModelImageEditorDialog
      ignoreDialogTrigger
      triggerChildren={
        <div
          ref={containerRef}
          className="grid grid-cols-2 gap-4"
          style={{
            gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))`,
          }}
        >
          {columns.map((predictions, index) => (
            <div key={index}>
              <div className="grid gap-4">
                {predictions.map((prediction) => (
                  <CustomModelPrediction key={prediction.id} prediction={prediction} />
                ))}
              </div>
            </div>
          ))}
        </div>
      }
    />
  );
}
