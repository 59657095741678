import { doesUserHaveTeamFullPermission } from "@/core/utils/user-role-utils";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { CURRENT_TEAM_ID_KEY } from "components/constants/backend";
import { FloatTagZIndex } from "components/constants/zIndex";
import {
  ManageSubscriptionDialog,
  useManageSubscriptionDialogContext,
} from "components/popup/message-dialog/manage-subscription-dialog";
import { editorContextStore } from "contexts/editor-context";
import { CreditCard, LogOut, Users } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  ManageTeamDialog,
  useManageTeamDialogContext,
} from "../popup/message-dialog/manage-team-dialog";

const dropdownMenuItemClassName =
  "w-full flex flex-row items-center justify-start px-4 py-2 text-zinc-300 hover:text-lime-500 hover:outline-none select-none cursor-pointer transition-colors";

const iconSize = 14;

function DividerEmpty() {
  return <div className="h-2" />;
}
function ManageTeamText() {
  return (
    <>
      <span className="pt-[2px]">
        <Users size={iconSize} />
      </span>
      <span className="ml-3">Manage Team</span>
    </>
  );
}

function ManageSubscriptionText() {
  const userHasFullPermission = doesUserHaveTeamFullPermission(editorContextStore.getState());
  return (
    <>
      <span className="pt-[2px]">
        <CreditCard size={iconSize} />
      </span>
      <span className="ml-3">
        {userHasFullPermission ? "Manage Subscription" : "View Subscription"}
      </span>
    </>
  );
}

function LogOutText() {
  return (
    <>
      <span className="pt-[2px]">
        <LogOut size={14} />
      </span>
      <span className="ml-3">Log out</span>
    </>
  );
}

export function NavBarUserProfile() {
  const user = editorContextStore((state) => state.user);
  const backend = editorContextStore((state) => state.backend);
  const setProjectId = editorContextStore((state) => state.setProjectId);
  const navigate = useNavigate();
  const name = user?.displayName || user?.email || "Anon";
  const initial = name.charAt(0);
  const { setOpen: setOpenSubscription } = useManageSubscriptionDialogContext();
  const { setOpen: setOpenTeam } = useManageTeamDialogContext();

  return (
    <DropdownMenu.Root>
      <ManageSubscriptionDialog />
      <ManageTeamDialog />
      <DropdownMenu.Trigger className="w-7 h-7 flex justify-center items-center rounded-full text-sm bg-lime-500 text-zinc-900 font-semibold select-none shadow-md focus-visible:outline-none">
        {initial.toUpperCase()}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        sideOffset={14}
        className="bg-zinc-900 shadow-md border border-zinc-800 rounded-md min-w-[230px] max-w-[350px] text-zinc-300 text-sm flex flex-col justify-center items-start"
        style={{
          zIndex: FloatTagZIndex,
        }}
      >
        <div className="w-full px-4 py-2 text-zinc-500 border-b border-zinc-800 truncate">
          {name}
        </div>
        <DividerEmpty />
        <DropdownMenu.Item
          className={dropdownMenuItemClassName}
          onClick={() => {
            setOpenSubscription(true);
          }}
        >
          <ManageSubscriptionText />
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className={dropdownMenuItemClassName}
          onClick={() => {
            setOpenTeam(true);
          }}
        >
          <ManageTeamText />
        </DropdownMenu.Item>

        <DropdownMenu.Item
          className={dropdownMenuItemClassName}
          onClick={() => {
            console.log("Sign out user.");
            setProjectId?.(undefined);
            backend?.signOutUser().then(() => {
              editorContextStore.getState().setUser(null);
              editorContextStore.getState().setUserTeams({});
              editorContextStore.getState().setCurrentTeamId(null);
              localStorage.removeItem(CURRENT_TEAM_ID_KEY);
              navigate("/login");
            });
          }}
        >
          <LogOutText />
        </DropdownMenu.Item>
        <DividerEmpty />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
