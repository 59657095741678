import { CustomModelPlaygroundPromptEditorState, StateUpdater } from "@/core/common/types";
import React, { createContext, useContext, useState } from "react";
import { getEmptyCustomModelPlaygroundPromptEditorState } from "./custom-model-playground-context";

export enum CustomModelVirtualTryOnStatus {
  Idle = "Idle",
  Loading = "Loading",
}

interface CustomModelVirtualTryOnContextType {
  clothImageStoragePath: string | undefined;
  setClothImageStoragePath: (value: StateUpdater<string | undefined>) => void;
  status: CustomModelVirtualTryOnStatus;
  setStatus: (value: StateUpdater<CustomModelVirtualTryOnStatus>) => void;
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  setPromptEditorState: (value: StateUpdater<CustomModelPlaygroundPromptEditorState>) => void;
  runUpscale: boolean;
  setRunUpscale: (value: StateUpdater<boolean>) => void;
}

// Create the context
const CustomModelVirtualTryOnContext = createContext<
  CustomModelVirtualTryOnContextType | undefined
>(undefined);

// Create a custom hook to use the context
export function useCustomModelVirtualTryOnContext() {
  const context = useContext(CustomModelVirtualTryOnContext);

  if (!context) {
    throw new Error(
      "useCustomModelVirtualTryOnContext must be used within a CustomModelVirtualTryOnContextProvider",
    );
  }

  return context;
}

// Create the context provider component
export function CustomModelVirtualTryOnContextProvider({ children }: React.PropsWithChildren) {
  const [clothImageStoragePath, setClothImageStoragePath] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState(CustomModelVirtualTryOnStatus.Idle);
  const [runUpscale, setRunUpscale] = useState(false);
  const [promptEditorState, setPromptEditorState] =
    useState<CustomModelPlaygroundPromptEditorState>(
      getEmptyCustomModelPlaygroundPromptEditorState(),
    );

  return (
    <CustomModelVirtualTryOnContext.Provider
      value={{
        clothImageStoragePath,
        setClothImageStoragePath,
        status,
        setStatus,
        promptEditorState,
        setPromptEditorState,
        runUpscale,
        setRunUpscale,
      }}
    >
      {children}
    </CustomModelVirtualTryOnContext.Provider>
  );
}
