import { AnalyticsConfig } from "@/analytics/config";
import { FullScreenAuthContainer } from "@/components/auth/auth-conatiner";
import { InputBoxClassName, InputBoxClassNameError } from "@/components/constants/class-names";
import { OrDividerHorizontal } from "@/components/dividers/divider-horizontal-misc";
import { SimpleSpinner } from "@/components/icons/simple-spinner";
import { editorContextStore } from "@/contexts/editor-context";
import { AppUser } from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import { debounce, noop } from "lodash";
import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { LoginWithEmail, LoginWithGoogle } from "./login-fullscreen";

type InviteMessageState = "empty" | "invalid" | "used" | "valid" | "loading";

const InviteCodeContext = React.createContext<{
  inviteCode?: string;
  setInviteCode: (value: string | undefined) => void;
  isLoadingInviteCode: boolean;
  setIsLoadingInviteCode: (value: boolean) => void;
  inviteMessageState: InviteMessageState;
  setInviteMessageState: (value: InviteMessageState) => void;
}>({
  inviteCode: undefined,
  setInviteCode: noop,
  isLoadingInviteCode: true,
  setIsLoadingInviteCode: noop,
  inviteMessageState: "empty",
  setInviteMessageState: noop,
});

export function useInviteCodeContext() {
  return React.useContext(InviteCodeContext);
}

export function InviteCodeContextProvider({ children }: { children: React.ReactNode }) {
  const [inviteCode, setInviteCode] = React.useState<string>();
  const [isLoadingInviteCode, setIsLoadingInviteCode] = React.useState(true);
  const [inviteMessageState, setInviteMessageState] = React.useState<InviteMessageState>("empty");
  const backend = editorContextStore((state) => state.backend);
  React.useEffect(() => {
    let unsubscribeAuthChange: (() => void) | undefined = undefined;
    if (backend) {
      unsubscribeAuthChange = backend.onAuthStateChanged(() => {
        setIsLoadingInviteCode(true);
        backend
          .doesUserHaveInviteCode()
          .then((response) => {
            const inviteCode = response.data.inviteCode;
            if (inviteCode) {
              setInviteCode(inviteCode);
            } else {
              console.warn(response.data.message);

              editorContextStore.getState().analytics.track(AnalyticsConfig.InviteCodeRequired, {
                valid: false,
                message: response.data.message,
              });
            }
          })
          .catch(console.error)
          .finally(() => {
            setIsLoadingInviteCode(false);
          });
      });
    }
    return () => {
      unsubscribeAuthChange?.();
    };
  }, [backend]);
  return (
    <InviteCodeContext.Provider
      value={{
        inviteCode,
        setInviteCode,
        isLoadingInviteCode,
        setIsLoadingInviteCode,
        inviteMessageState,
        setInviteMessageState,
      }}
    >
      {children}
    </InviteCodeContext.Provider>
  );
}

export function RequireInviteCode({
  children,
  redirect = "/inviteCode",
}: {
  children: React.ReactNode;
  redirect?: string;
}) {
  const { inviteCode, isLoadingInviteCode } = React.useContext(InviteCodeContext);

  React.useEffect(() => {
    editorContextStore.getState().analytics.track(AnalyticsConfig.InviteCodeRequired, {
      inviteCode,
      valid: Boolean(inviteCode),
    });
  }, [inviteCode]);

  if (isLoadingInviteCode) {
    return (
      <>
        <div className="w-screen h-screen flex flex-col justify-center items-center text-base text-zinc-500 bg-zinc-900">
          <SimpleSpinner />
          Loading ...
        </div>
        <div className="hidden invisible">{children}</div>
      </>
    );
  }

  return inviteCode ? (
    <>{children}</>
  ) : (
    <Navigate
      replace
      to={redirect}
      state={{
        isRedirected: true,
      }}
    />
  );
}

function InviteMessage({
  state,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  state?: InviteMessageState;
}) {
  if (state === "empty") {
    return (
      <div className={className} {...props}>
        Didn't receive the code?{" "}
        <Link className="text-lime-500 hover:text-lime-300 transition-colors" to="/login">
          Log in
        </Link>{" "}
        without an invite code.
      </div>
    );
  }
  if (state === "invalid") {
    return (
      <div className={classNames(className ?? "", "text-red-700")} {...props}>
        Invite code is invalid.{" "}
        <Link className="text-lime-500 hover:text-lime-300 transition-colors" to="/login">
          Log in
        </Link>{" "}
        without an invite code.
      </div>
    );
  }
  if (state === "used") {
    return (
      <div className={classNames(className ?? "", "text-red-700")} {...props}>
        Invite code is used.{" "}
        <Link className="text-lime-500 hover:text-lime-300 transition-colors" to="/login">
          Log in
        </Link>{" "}
        without an invite code.
      </div>
    );
  }
  if (state === "valid") {
    return (
      <div className={classNames(className ?? "", "text-zinc-300")} {...props}>
        ✅ Invite code is valid. Choose a login method below.
      </div>
    );
  }
  if (state === "loading") {
    return (
      <div className={classNames(className || "", "flex flex-row items-center")} {...props}>
        <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />{" "}
        <span className="w-2" /> Checking if the invite code is valid ...
      </div>
    );
  }
  return <div />;
}

function checkIfInviteCodeEmpty(
  inviteCode: string | undefined,
  inviteMessageState: InviteMessageState,
) {
  return Boolean(inviteCode) === false || inviteMessageState === "loading";
}

function checkIfInviteCodeInvalid(inviteMessageState: InviteMessageState) {
  return Boolean(inviteMessageState === "invalid" || inviteMessageState === "used");
}

function useCheckInviteCodeDebounce() {
  const { setInviteMessageState } = React.useContext(InviteCodeContext);

  // Create the base validation function
  const validateInviteCode = React.useCallback(
    async (inviteCode: string) => {
      if (!inviteCode) {
        return false;
      }

      const backend = editorContextStore.getState().backend;
      if (!backend) {
        return false;
      }

      const { exists, isUsed } = await backend.isInviteCodeValid(inviteCode);

      if (!exists) {
        setInviteMessageState("invalid");
        return false;
      }

      if (isUsed) {
        setInviteMessageState("used");
        return false;
      }

      setInviteMessageState("valid");
      return true;
    },
    [setInviteMessageState],
  );

  // Return the debounced version of the validation function
  return React.useMemo(() => debounce(validateInviteCode, 1000), [validateInviteCode]);
}

function setInviteCodeUsed(inviteCode: string, email: string) {
  return editorContextStore
    .getState()
    .backend?.setInviteCodeUsed(inviteCode, email, "v2")
    .then((response) => {
      console.log("Get response from using invite code");
      console.log(response.data);
    })
    .catch((error) => {
      console.warn("Get error from using invite code");
      console.warn(error);
    });
}

function FullScreenInviteCodeEnter() {
  const { inviteCode, setInviteCode, inviteMessageState, setInviteMessageState } =
    React.useContext(InviteCodeContext);
  const [isSigningIn, setIsSigningIn] = React.useState(false);

  const isInviteCodeEmpty = checkIfInviteCodeEmpty(inviteCode, inviteMessageState);
  const isInviteCodeInvalid = checkIfInviteCodeInvalid(inviteMessageState);

  const checkInviteCodeDebounce = useCheckInviteCodeDebounce();
  const navigate = useNavigate();

  const handleSignIn = React.useCallback(
    async (user: AppUser | null) => {
      setIsSigningIn(false);
      if (!user || !inviteCode) {
        return;
      }
      if (user?.email) {
        setInviteCodeUsed(inviteCode, user.email);
      }
      editorContextStore.getState().analytics.track(AnalyticsConfig.UserLogIn, {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
      });

      editorContextStore.getState().setUser(user);
      setTimeout(() => {
        navigate("/");
      }, 50);
    },
    [navigate, inviteCode],
  );

  return (
    <>
      <div className="my-4 text-xl font-semibold text-zinc-300">
        Enter invite code below to try Flair.
      </div>
      <div className="w-full flex flex-col justify-center">
        <label htmlFor="invite-code" className="py-2">
          Invite Code
        </label>
        <input
          id="invite-code"
          type="text"
          value={inviteCode ?? ""}
          className={classNames(
            "text-lg",
            isInviteCodeInvalid ? InputBoxClassNameError : InputBoxClassName,
          )}
          onChange={(e) => {
            if (isInviteCodeInvalid) {
              setInviteMessageState("empty");
            }
            const inviteCode = e.target.value?.trim();
            setInviteCode(inviteCode);
            if (!inviteCode) {
              return setInviteMessageState("empty");
            }
            setInviteMessageState("loading");
            checkInviteCodeDebounce(inviteCode);
          }}
        />
        <div className="h-2" />
        <InviteMessage state={inviteMessageState} />
        <div className="h-2" />
        {isInviteCodeEmpty || isInviteCodeInvalid ? (
          <></>
        ) : (
          <>
            <div className="h-2" />
            <LoginWithGoogle
              disabled={isInviteCodeEmpty || isInviteCodeInvalid}
              isSigningIn={isSigningIn}
              setIsSigningIn={setIsSigningIn}
              onSignIn={handleSignIn}
            />
            <OrDividerHorizontal className="my-2" />
            <LoginWithEmail
              disabled={isInviteCodeEmpty || isInviteCodeInvalid}
              isLoading={isSigningIn}
              setLoading={setIsSigningIn}
              onEmailSent={(email) => {
                if (!inviteCode) {
                  return;
                }
                setInviteCodeUsed(inviteCode, email);
              }}
            />
          </>
        )}
        <div className="h-4" />
      </div>
    </>
  );
}

export function FullScreenInviteCodeInput() {
  return (
    <FullScreenAuthContainer>
      <FullScreenInviteCodeEnter />
    </FullScreenAuthContainer>
  );
}
