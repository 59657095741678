import { CURRENT_TEAM_ID_KEY } from "@/components/constants/backend";
import { useManageTeamDialogContext } from "@/components/popup/message-dialog/manage-team-dialog";
import { PublicTeamId } from "@/core/common/types/team";
import { AppRoleType } from "@/core/common/types/user-roles";
import { classNames } from "@/core/utils/classname-utils";
import { getCurrentTeamId } from "@/core/utils/team-utils";
import { getObjectEntries } from "@/core/utils/type-utils";
import { getTeamUserRole } from "@/core/utils/user-role-utils";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { DropdownClassName, DropdownMenuItemClassName } from "components/constants/class-names";
import { editorContextStore } from "contexts/editor-context";
import { ChevronDown, UserRoundPlus } from "lucide-react";
import React from "react";

function AddTeamMembersButton() {
  const { setOpen: setOpenTeam } = useManageTeamDialogContext();
  return (
    <button
      className="flex flex-row items-center justify-start gap-1 cursor-pointer bg-lime-900/50 p-1.5 rounded transition-colors pl-[9px]"
      onClick={() => {
        setOpenTeam(true);
      }}
    >
      <UserRoundPlus
        size={18}
        className="text-lime-500 group-hover:text-lime-300 transition-colors"
      />
    </button>
  );
}

export function DashboardLeftPanelTeamsDropdown() {
  const userTeams = editorContextStore((state) => state.userTeams);
  const setCurrentTeamId = editorContextStore((state) => state.setCurrentTeamId);
  const setDashboardType = editorContextStore((state) => state.setDashboardType);
  const userRole = getTeamUserRole(editorContextStore((state) => state));

  React.useEffect(() => {
    const savedTeamId = localStorage.getItem(CURRENT_TEAM_ID_KEY);
    if (savedTeamId && userTeams[savedTeamId]) {
      setCurrentTeamId(savedTeamId as PublicTeamId);
    }
  }, [userTeams, setCurrentTeamId]);

  const currentTeamId = getCurrentTeamId();

  const currentTeam = React.useMemo(
    () => currentTeamId && userTeams[currentTeamId],
    [userTeams, currentTeamId],
  );

  const numberOfTeams = Object.keys(userTeams).length;

  React.useEffect(() => {
    const dashboard =
      userRole === AppRoleType.Owner
        ? "templates"
        : userRole === AppRoleType.Writer
          ? "models"
          : userRole === AppRoleType.Reader
            ? "assets"
            : null;

    setDashboardType(dashboard);
  }, [currentTeamId, userRole, setDashboardType]);

  if (!userTeams) {
    return null;
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="w-full group flex flex-row items-center justify-start gap-1 cursor-pointer">
        <div className="border-b border-zinc-800 w-full flex flex-row items-center justify-start p-4 gap-2">
          <span className="min-w-0 lg:w-[200px] flex-1 text-zinc-300 group-hover:text-zinc-100 transition-colors truncate h-[24px]">
            {currentTeam?.name}
          </span>
          {numberOfTeams == 1 ? (
            <AddTeamMembersButton />
          ) : (
            <ChevronDown
              size={18}
              className="text-zinc-500 group-hover:text-zinc-300 transition-colors"
            />
          )}
        </div>
      </DropdownMenu.Trigger>
      {numberOfTeams > 1 && (
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={classNames(
              DropdownClassName,
              "hidden md:flex bg-zinc-900 shadow-md border border-zinc-800 rounded-md min-w-[230px] max-w-[350px] text-zinc-300 text-sm flex-col justify-center items-start ",
            )}
          >
            {getObjectEntries(userTeams).map(([teamId, team]) => (
              <DropdownMenu.Item
                key={teamId}
                className={classNames(DropdownMenuItemClassName, "max-w-[235px] truncate")}
                onClick={() => {
                  editorContextStore.getState().setCurrentTeamId(teamId);
                  localStorage.setItem(CURRENT_TEAM_ID_KEY, teamId);
                }}
              >
                {team.name}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      )}
    </DropdownMenu.Root>
  );
}
