import { AnalyticsConfig } from "@/analytics/config";
import { InputBoxClassName } from "@/components/constants/class-names";
import { OrDividerHorizontal } from "@/components/dividers/divider-horizontal-misc";
import GoogleIcon from "@/components/icons/google-icon";
import { isMobile } from "@/components/utils/is-mobile";
import { editorContextStore } from "@/contexts/editor-context";
import { AppUser } from "@/core/common/types";
import { classNames } from "@/core/utils/classname-utils";
import { debugLog } from "@/core/utils/print-utilts";
import { isValidEmail } from "@/core/utils/string-utils";
import { getCurrentTeamId } from "@/core/utils/team-utils";
import { Mail } from "lucide-react";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ONBOARDING } from "../constants/routes";
import { FullScreenAuthContainer } from "./auth-conatiner";
import { useInviteCodeContext } from "./invite-code";

const buttonClassNameBase =
  "w-full rounded-md border-solid border border-zinc-700 transition-colors my-4 px-2 py-2 flex flex-row justify-center items-center shadow-md box-border";

const buttonClassName = classNames(
  buttonClassNameBase,
  "text-zinc-300 hover:text-lime-500 cursor-pointer hover:border-lime-500",
);

const buttonClassNameLoading = classNames(buttonClassNameBase, "text-zinc-500 cursor-wait");

const buttonClassNameError = classNames(buttonClassNameBase, "text-zinc-500 cursor-not-allowed");

export function LoginWithEmail({
  disabled = false,
  isLoading,
  setLoading,
  onEmailSent,
}: {
  disabled?: boolean;
  isLoading: boolean;
  setLoading: (value: boolean) => void;
  onEmailSent?: (email: string) => void;
}) {
  const [email, setEmail] = React.useState("");
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [isEmailSent, setIsEmailSent] = React.useState(false);

  React.useEffect(() => {
    setIsEmailValid(!disabled);
  }, [disabled]);

  React.useEffect(() => {
    if (!email) {
      return setIsEmailValid(false);
    }
    setIsEmailValid(isValidEmail(email));
  }, [email]);

  return (
    <>
      <label htmlFor="login-email-input" className="w-full mb-2">
        Email
      </label>
      <input
        id="login-email-input"
        type="email"
        className={classNames(InputBoxClassName, "py-3")}
        value={email}
        onChange={(e) => {
          setEmail(e.currentTarget.value);
        }}
        placeholder="Your email address"
      />
      <button
        className={classNames(
          isEmailValid
            ? isLoading
              ? buttonClassNameLoading
              : buttonClassName
            : buttonClassNameError,
        )}
        onClick={() => {
          if (!isEmailValid) {
            return;
          }
          if (isLoading) {
            return;
          }
          debugLog("Send email login");
          setLoading(true);
          editorContextStore
            .getState()
            .backend?.sendEmailLoginLink({
              email,
            })
            ?.then(() => {
              onEmailSent?.(email);
              setTimeout(() => {
                setLoading(false);
                setIsEmailSent(true);
              }, 1000);
            });
        }}
      >
        <Mail size={18} className="mr-5 my-2" />
        {isEmailValid
          ? isLoading
            ? "Sending login link ..."
            : isEmailSent
              ? "Resend login email"
              : "Continue with email"
          : isEmailSent
            ? "Resend login email"
            : "Continue with email"}
      </button>
    </>
  );
}

export function LoginWithGoogle({
  disabled = false,
  isSigningIn,
  setIsSigningIn,
  onSignIn,
}: {
  disabled?: boolean;
  isSigningIn: boolean;
  setIsSigningIn: (value: boolean) => void;
  onSignIn: (user: AppUser | null) => void;
}) {
  return (
    <button
      type="button"
      className={classNames(
        disabled ? buttonClassNameError : isSigningIn ? buttonClassNameLoading : buttonClassName,
      )}
      onClick={() => {
        if (disabled) {
          return;
        }
        if (isSigningIn) {
          return;
        }
        const { backend } = editorContextStore.getState();
        if (backend) {
          debugLog("Login with fullscreen");
          setIsSigningIn(true);
          backend.continueWithGoogle().then(onSignIn).catch(console.error);
        }
      }}
    >
      <GoogleIcon />
      <span className="mr-2">Continue with Google</span>
    </button>
  );
}

function LoginFullscreenInternal({ onLoggedIn }: { onLoggedIn?: (email: string) => void }) {
  const [message, setMessage] = React.useState("");
  const [isSigningIn, setIsSigningIn] = React.useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const backend = editorContextStore((state) => state.backend);
  const setUser = editorContextStore((state) => state.setUser);
  const setUserOnboardData = editorContextStore((state) => state.setUserOnboardData);
  const user = editorContextStore((state) => state.user);
  const currentTeamId = getCurrentTeamId();

  const handleSignIn = React.useCallback(
    async (user: AppUser | null) => {
      setIsSigningIn(false);
      if (!user) {
        return;
      }
      if (user?.email) {
        setMessage("Successfully signed in, redirecting ...");
      }
      onLoggedIn?.(user.email ?? "");
      editorContextStore.getState().analytics.track(AnalyticsConfig.UserLogIn, {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
      });
      setUser(user);

      try {
        let redirectTo = searchParams.get("redirectTo") || "/";

        // If currentTeamId has been set from an invite link, we don't need to show the welcome screen
        if (!currentTeamId) {
          const fetchedUserOnboardData = await backend?.getUserOnboardData(user?.uid);
          const hasSeenWelcomeScreen = fetchedUserOnboardData?.hasSeenWelcomeScreen;

          if (!hasSeenWelcomeScreen) {
            const updatedUserOnboardData = {
              ...fetchedUserOnboardData,
              hasSeenWelcomeScreen: true,
            };

            await backend?.setUserOnboardData({
              userOnboardData: updatedUserOnboardData,
            });

            setUserOnboardData(updatedUserOnboardData);
            redirectTo = `/${ONBOARDING}`;
          }
        }

        debugLog("Login redirect to ", redirectTo);

        setTimeout(() => {
          navigate(redirectTo, { replace: true });
        }, 50);
      } catch (error) {
        console.error("Error handling sign-in:", error);
        navigate("/", { replace: true });
      }
    },
    [navigate, onLoggedIn, searchParams, setUser, backend, setUserOnboardData, currentTeamId],
  );

  React.useEffect(() => {
    if (!searchParams || isSigningIn || !backend) {
      return;
    }

    const authToken = searchParams.get("authToken");
    if (authToken) {
      const isMobileBrowser = isMobile({
        tablet: true,
        featureDetect: true,
      });
      if (isMobileBrowser) {
        // Redirect to mobile redirect error page
        navigate("/mobileError");
        return;
      } else {
        setIsSigningIn(true);
        backend.signInWithCustomToken(authToken).then(handleSignIn);
      }
    }
  }, [navigate, backend, searchParams, isSigningIn, handleSignIn]);

  React.useEffect(() => {
    handleSignIn(user as AppUser | null);
    // eslint-disable-next-line
  }, [user]);

  return (
    <FullScreenAuthContainer>
      <div className="my-4 text-xl font-semibold text-zinc-300">Get Started</div>
      <LoginWithGoogle
        isSigningIn={isSigningIn}
        setIsSigningIn={setIsSigningIn}
        onSignIn={handleSignIn}
      />
      <OrDividerHorizontal className="my-2" />
      <LoginWithEmail isLoading={isSigningIn} setLoading={setIsSigningIn} />
      <div className="w-full text-center">
        Have an invite code for free credits?{" "}
        <Link className="text-lime-500 hover:text-lime-300 transition-colors" to="/inviteCode">
          Click Here
        </Link>
        .
      </div>
      <div className="w-full mt-2 text-center text-zinc-200">{message}</div>
    </FullScreenAuthContainer>
  );
}

export function LoginFullscreen() {
  const { inviteCode } = useInviteCodeContext();
  return (
    <LoginFullscreenInternal
      onLoggedIn={(email) => {
        if (inviteCode) {
          editorContextStore
            .getState()
            .backend?.setInviteCodeUsed(inviteCode, email, "v2")
            .then((response) => {
              debugLog("Get response from using invite code");
              debugLog(response.data);
            })
            .catch((error) => {
              console.warn("Get error from using invite code");
              console.warn(error);
            });
        }
      }}
    />
  );
}
