import { UpdateUserAssetInfoArgs, UploadUserAssetInfoArgs } from "@/backend/base";
import { FlairStorageManager } from "@/backend/firebase/storage/storage-manager";
import { editorContextStore } from "@/contexts/editor-context";
import {
  EditorAsset,
  EditorAssetContentType,
  OnAddAssetEventHandler,
  PastGeneration,
  UserAssetInfoType,
  UserAssetType,
} from "@/core/common/types";
import { getDataUrlFromBlob, getDataUrlFromString } from "@/core/utils/asset-utils";
import { getAssetIdFromStoragePath } from "@/core/utils/image-utils";
import { removeKeyFromObjectImmutable } from "@/core/utils/object-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { isDataURL, isValidFirebaseStoragePath, isValidHttpsUrl } from "@/core/utils/string-utils";
import { isFabricObject, isFabricObjectWithAsset } from "@/core/utils/type-guards";
import { fabric } from "fabric";
import { Base } from "./base";

export class Assets extends Base {
  private assetStore: Record<string, string> = {};

  private saveAssetToMemory({ path, asset }: { path: string; asset: string }) {
    const storageManager = editorContextStore.getState().storageManager;
    const cleanPath = storageManager?.cleanupStoragePathURL(path) || path;
    debugLog("saveAssetToMemory", cleanPath, asset);
    this.assetStore[cleanPath] = asset;
  }

  private deleteAssetFromMemory({ path }: { path: string }) {
    delete this.assetStore[path];
  }

  private loadAssetFromMemory({ path }: { path: string }) {
    const storageManager = editorContextStore.getState().storageManager;
    const cleanPath = storageManager?.cleanupStoragePathURL(path) || path;
    return this.assetStore[cleanPath];
  }

  private saveAssetToLocal({ path, asset }: { path: string; asset: string }) {
    return window?.localStorage.setItem(path, asset);
  }

  private loadAssetFromLocal({ path }: { path: string }) {
    return window?.localStorage.getItem(path);
  }

  private deleteAssetFromLocal({ path }: { path: string }) {
    return window?.localStorage.removeItem(path);
  }

  private static async getAssetDataUrlFromData(data: string | File | Blob) {
    if (!data) {
      return;
    }
    try {
      if (typeof data === "string") {
        return await getDataUrlFromString(data);
      }
      if (data instanceof Blob) {
        return await getDataUrlFromBlob(data);
      }
    } catch (error) {
      debugError("Cannot get asset data url from data", error);
    }
  }

  private static async loadAssetFromRemote({
    path,
    storageManager,
  }: {
    path: string;
    storageManager: FlairStorageManager;
  }) {
    try {
      return await storageManager?.getDownloadUrlFromStoragePath(path);
    } catch (error) {
      debugError(`Cannot load asset from path ${path}: `, error);
    }
    return null;
  }

  private async loadAssetFromRemote({ path }: { path: string }) {
    const storageManager = editorContextStore.getState().storageManager;
    if (storageManager) {
      debugLog("loadAssetFromRemote: attempting to load", path);
      const cleanPath = storageManager.cleanupStoragePathURL(path);
      const asset = await Assets.loadAssetFromRemote({
        path: cleanPath,
        storageManager,
      });

      if (asset) {
        debugLog("loadAssetFromRemote: success", cleanPath, asset);
        return asset;
      }
      debugError("loadAssetFromRemote: failed to load", cleanPath);
    }
    return null;
  }

  static getAssetTypeFromPath(path: string) {
    const storageManager = editorContextStore.getState().storageManager;
    const cleanPath = storageManager?.cleanupStoragePathURL(path) || path;

    if (isDataURL(cleanPath) || isValidHttpsUrl(cleanPath)) {
      return "image-url";
    } else if (isValidFirebaseStoragePath(cleanPath)) {
      return "image-storage";
    }
    return undefined;
  }

  static getEditorAssetFromPath(path?: string): EditorAsset | null {
    if (!path) {
      return null;
    }

    const type = Assets.getAssetTypeFromPath(path);

    if (!type) {
      return null;
    }

    return {
      path,
      type,
    };
  }

  static async loadAssetFromPath({
    path,
    storageManager,
  }: {
    path: string;
    storageManager: FlairStorageManager;
  }) {
    if (!path) {
      return null;
    }

    const type = Assets.getAssetTypeFromPath(path);

    if (type === "image-storage") {
      storageManager
        ?.appendPtidToFileMetadata({
          filePath: path,
        })
        // .then((status) => {
        //   debugLog(`Appended public team id to asset ${path}? `, status);
        // })
        .catch((error) => {
          debugError(`Error appending public team id to asset ${path}: `, error);
        });

      return await Assets.loadAssetFromRemote({
        storageManager,
        path,
      });
    } else if (type === "image-url") {
      return path;
    }

    return null;
  }

  /**
   * THIS SEEMS BROKEN?
   * - Editor loads assets, but the files not in editor dont load?
   * @param param0
   * @returns
   */
  public async loadAsset({
    type,
    path,
    saveToMemory = true,
    saveToLocal = false,
  }: Partial<EditorAsset> & {
    saveToMemory?: boolean;
    saveToLocal?: boolean;
  }) {
    if (!path) {
      debugLog("loadAssetFromPath error: path is invalid");
      return undefined;
    }

    type = Assets.getAssetTypeFromPath(path) || type;
    debugLog(`Asset ${path} is of type: ${type}`);

    // Get asset from backend storage
    if (type === "image-storage") {
      let asset: string | undefined | null = undefined;

      // Try memory cache first
      asset = this.loadAssetFromMemory({ path });
      if (asset) {
        debugLog(`Asset ${path} already exists in memory.`);
        return asset;
      }

      asset = this.loadAssetFromMemory({ path });
      if (asset) {
        return asset;
      }

      // Try local storage next
      asset = this.loadAssetFromLocal({ path });
      if (asset) {
        if (saveToMemory) {
          this.saveAssetToMemory({
            path,
            asset,
          });
        }
        return asset;
      }

      asset = await this.loadAssetFromRemote({ path });
      if (asset) {
        if (saveToMemory) {
          this.saveAssetToMemory({
            path,
            asset,
          });
        }
        if (saveToLocal) {
          this.saveAssetToLocal({
            path,
            asset,
          });
        }
        return asset;
      } else {
        debugError("Cannot load asset from path: ", path);
      }
      debugError("loadAsset: failed to load from any source", path);
    } else if (type === "image-url") {
      return path;
    } else {
      debugError(`Asset of type ${type} is invalid.`);
    }

    return undefined;
  }

  private async uploadAsset({
    data,
    contentType,
    assetId,
    assetType,
  }: {
    data: string | File | Blob;
    contentType: EditorAssetContentType;
    assetId?: string;
    assetType?: UserAssetType;
  }) {
    const backend = this.editor.state.backend;
    const storageManager = this.editor.state.storageManager;
    const projectId = this.editor.state.projectId;
    if (!backend || !storageManager) {
      return undefined;
    }
    let path = undefined;
    if (typeof data === "string") {
      if (isDataURL(data)) {
        path = await storageManager.uploadDataUrlToStorage({
          data,
          contentType,
          assetId,
          assetType,
          projectId,
        });
      } else if (contentType === EditorAssetContentType.json) {
        path = await storageManager?.uploadJsonToStorage({
          data,
          assetId,
          assetType,
          projectId,
        });
      } else {
        debugError("Cannot upload asset because the data is not a valid data-url or json.");
      }
    } else {
      path = await storageManager?.uploadFileToStorage({
        contentType,
        data,
        assetType,
        projectId,
      });
    }

    if (path) {
      this.editor.emit<OnAddAssetEventHandler>("assets:on-add", {
        path,
        contentType,
      });
    }

    return path;
  }

  private async saveAsset({
    path,
    data,
    saveToMemory = true,
    saveToLocal = false,
  }: {
    path: string;
    data: string | File | Blob;
    saveToMemory?: boolean;
    saveToLocal?: boolean;
  }) {
    if (saveToLocal || saveToMemory) {
      const dataUrl = await Assets.getAssetDataUrlFromData(data);
      if (!dataUrl) {
        return;
      }
      if (saveToMemory) {
        this.saveAssetToMemory({
          path,
          asset: dataUrl,
        });
      }
      if (saveToLocal) {
        this.saveAssetToLocal({
          path,
          asset: dataUrl,
        });
      }
    }
  }

  public async deleteUserImageAsset({
    assetId,
    assetType = "images",
    storagePath,
    removeFromLocal = true,
    removeFromMemory = false,
  }: {
    assetId?: string | null;
    storagePath?: string;
    assetType?: UserAssetInfoType;
    removeFromLocal?: boolean;
    removeFromMemory?: boolean;
  }) {
    debugLog("deleteUserImageAsset: starting deletion with params", {
      assetId,
      assetType,
      storagePath,
      removeFromLocal,
      removeFromMemory,
    });

    // Check if we have either assetId or storagePath
    if (!storagePath && !assetId) {
      debugError("deleteUserImageAsset: neither storagePath nor assetId provided");
      return;
    }

    const {
      backend,
      storageManager,
      setUserImageAssetInfoCollection,
      setAssetMetadataCollection,
      assetMetadataCollection,
    } = this.editor.state;

    // If we don't have storagePath but have assetId, try to get storagePath from metadata
    if (!storagePath && assetId && assetMetadataCollection) {
      const metadata = assetMetadataCollection[assetId];
      if (metadata?.storagePath) {
        storagePath = metadata.storagePath;
        debugLog("deleteUserImageAsset: found storagePath from metadata", storagePath);
      }
    }

    // Handle local/memory cleanup if we have a storagePath
    if (storagePath) {
      if (removeFromLocal) {
        this.deleteAssetFromLocal({
          path: storagePath,
        });
      }

      if (removeFromMemory) {
        this.deleteAssetFromMemory({
          path: storagePath,
        });
      }
    } else {
      debugLog(
        "deleteUserImageAsset: proceeding without storagePath - skipping local/memory cleanup",
      );
    }

    // Use provided assetId or try to get it from storagePath
    const id = assetId || (storagePath ? getAssetIdFromStoragePath(storagePath) : null);
    if (!id) {
      debugError("deleteUserImageAsset: could not determine assetId");
      return;
    }

    // Check if this is an asset from the new V2 storage
    const isV2Asset = storagePath?.includes(FlairStorageManager.ASSET_STORAGE_PREFIX) ?? false;

    try {
      if (isV2Asset) {
        debugLog("deleteUserImageAsset: deleting V2 asset", id);
        setAssetMetadataCollection((prevCollection) =>
          removeKeyFromObjectImmutable(prevCollection, id),
        );
        await storageManager?.deleteAssetMetadata({
          assetId: id,
        });
      } else {
        debugLog("deleteUserImageAsset: deleting V1 asset", id);
        setUserImageAssetInfoCollection((prevCollection) =>
          removeKeyFromObjectImmutable(prevCollection, id),
        );
        await backend?.deleteUserAssetInfo({
          assetId: id,
          assetType,
        });
      }
      debugLog("deleteUserImageAsset: successfully deleted asset", id);
    } catch (error) {
      debugError("deleteUserImageAsset: error deleting asset", error);
    }
  }

  public async addAsset({
    data,
    contentType,
    assetType,
    saveToMemory = true,
    saveToLocal = false,
    assetId,
  }: {
    data: string | File | Blob;
    contentType: EditorAssetContentType;
    assetType?: UserAssetType;
    saveToMemory?: boolean;
    saveToLocal?: boolean;
    assetId?: string;
  }) {
    if (!data) {
      return undefined;
    }

    const path = await this.uploadAsset({
      data,
      contentType,
      assetType,
      assetId,
    });
    if (path) {
      await this.saveAsset({
        path,
        data,
        saveToMemory,
        saveToLocal,
      });
    }
    return path;
  }

  getObjectAssetId(object: fabric.Object | string) {
    object = typeof object === "string" ? this.editor.objects.findOneById(object) : object;

    if (isFabricObjectWithAsset(object)) {
      return getAssetIdFromStoragePath(object.asset.path);
    }

    return null;
  }

  public setObjectAsset(objectId: string, asset: EditorAsset) {
    const object = this.editor.objects.findOneById(objectId);
    if (isFabricObject(object)) {
      // @ts-ignore
      object.set("asset", asset);
      this.editor.history.save();
    } else {
      console.log(`Cannot set asset of invalid object ${objectId}`);
    }
  }

  public async setUserAssetInfo(args: UploadUserAssetInfoArgs) {
    const { backend, setUserImageAssetInfoCollection } = this.state;

    if (!backend) {
      return;
    }

    const response = await backend?.addUserAssetInfo(args);

    if (!response) {
      return;
    }

    const result = response.isUpdated && response.result;

    if (!result) {
      return;
    }

    setUserImageAssetInfoCollection((prevCollection) => ({
      [result.id]: result,
      ...prevCollection,
    }));
  }

  public async updateUserAssetInfo(args: UpdateUserAssetInfoArgs) {
    const { backend, setUserImageAssetInfoCollection } = this.state;

    if (!backend) {
      return;
    }

    const response = await backend.updateUserAssetInfo(args);

    if (!response) {
      return;
    }

    const result = response.isUpdated && response.result;

    if (!result) {
      return;
    }

    setUserImageAssetInfoCollection((prevCollection) => ({
      [result.id]: result,
      ...prevCollection,
    }));
  }

  public async updateObjectUserAssetInfo({
    object,
    ...args
  }: Partial<UpdateUserAssetInfoArgs> & {
    object: fabric.Object | fabric.StaticImage;
    assetType: UserAssetInfoType;
  }) {
    if (!object) {
      return;
    }
    const assetId = this.getObjectAssetId(object as any as fabric.Object);

    if (!assetId) {
      return;
    }

    return await this.updateUserAssetInfo({
      ...args,
      assetId,
    });
  }

  public async getPastGeneration(generationId?: string) {
    if (!generationId) {
      return;
    }
    const backend = this.editor.state.backend;
    const { pastGenerations, setPastGenerations } = this.editor.state;
    if (!backend || !pastGenerations) {
      return;
    }
    let generation = pastGenerations[generationId];
    if (generation) {
      return generation;
    }
    generation = await backend.getPastGeneration({
      generationId,
    });
    if (generation) {
      // Cache the generation
      setPastGenerations((generations) => ({
        ...generations,
        [generationId]: generation,
      }));
    }

    return generation;
  }

  public async addPastGenarations(pastGeneration: PastGeneration) {
    if (!pastGeneration) {
      return;
    }
    const backend = this.editor.state.backend;
    backend?.addPastGeneration({
      pastGeneration,
    });
    this.editor.state.setPastGenerations((pastGenerations) => {
      return {
        ...(pastGenerations ?? {}),
        [pastGeneration.id]: pastGeneration,
      };
    });
  }

  public async updatePastGeneration(
    pastGenerationPartial: Partial<PastGeneration> & { id: string },
  ) {
    if (!pastGenerationPartial) {
      return;
    }
    const pastGeneration = await this.getPastGeneration(pastGenerationPartial.id);
    if (!pastGeneration) {
      return;
    }

    const newPastGeneration = {
      ...pastGeneration,
      ...pastGenerationPartial,
    };

    const backend = this.editor.state.backend;
    backend?.addPastGeneration({
      pastGeneration: newPastGeneration,
    });
    this.editor.state.setPastGenerations((pastGenerations) => {
      return {
        ...(pastGenerations ?? {}),
        [newPastGeneration.id]: newPastGeneration,
      };
    });
  }
}
