import { OnboardingHeaderZIndex } from "components/constants/zIndex";
import { Logo } from "components/logo";
import StickyBox from "components/utils/sticky-box";
import React from "react";
import { useNavigate } from "react-router";
import { TextButtonClassName } from "../constants/class-names";
import {
  TemplateCategories,
  TemplateCategoryIcons,
  TemplateCategoryNamesAndOrder,
  Templates,
} from "../constants/user-project-type-styles";
import { TemplatesCarousel } from "../dashboard/dashboard-templates";
import { useOnboardingDialogContext } from "../popup/message-dialog/tool-onboarding-dialog";

export const Onboarding = React.memo(function Onboarding() {
  const navigate = useNavigate();
  const { setOpen } = useOnboardingDialogContext();
  return (
    <div className="w-screen h-screen flex flex-col bg-zinc-900 text-zinc-500 p-8 md:p-20 md:pt-0 overflow-y-auto gap-2">
      <StickyBox
        className="w-full flex flex-col gap-4 pt-0 md:pt-20 pb-16 bg-gradient-to-b from-zinc-900 via-zinc-900/100 via-80% to-transparent"
        style={{
          zIndex: OnboardingHeaderZIndex,
        }}
      >
        <Logo />
        <div className="flex flex-col gap-1">
          <div className="text-xl md:text-2xl text-zinc-100 font-bold">Welcome to Flair</div>
          <div className="text-base md:text-lg text-zinc-100">
            What would you like to start creating?
          </div>
        </div>
      </StickyBox>
      <div className="flex flex-col gap-2 mt-[-2em]">
        {Object.entries(TemplateCategoryNamesAndOrder).map(
          ([category, { name, description }], index) => {
            const templates = Templates.filter((template) =>
              template.categories.includes(category as TemplateCategories),
            );
            const TemplateIcon = TemplateCategoryIcons[category];

            return (
              templates.length > 0 &&
              TemplateCategoryNamesAndOrder[category].showInTemplatesDashboard && (
                <div key={index} className="flex flex-col gap-2 group/text">
                  <div className="flex flex-row text-base 2xl:text-lg text-zinc-500 group-hover/text:text-zinc-100 font-medium gap-1 items-center">
                    {TemplateIcon && (
                      <TemplateIcon size={20} strokeWidth={2.5} className="mb-0.5" />
                    )}
                    {name}
                  </div>
                  <TemplatesCarousel templates={templates} navigate={navigate} />
                </div>
              )
            );
          },
        )}
        <button
          className={`${TextButtonClassName} !justify-start`}
          onClick={() => {
            navigate("/");
            setOpen(true);
          }}
        >
          Or, navigate to the home page
        </button>
      </div>
    </div>
  );
});
