import { PublicUserMetadataDictItem } from "@/components/collaborate/public-user-roles";
import {
  DropdownClassName,
  DropdownMenuItemClassName,
  SecondaryButtonClassName,
} from "@/components/constants/class-names";
import { MessageDialogZIndex } from "@/components/constants/zIndex";
import { SimpleSpinner } from "@/components/icons/simple-spinner";
import {
  EditorAlertDialog,
  EditorAlertDialogActionButton,
  EditorAlertDialogCancelButton,
  EditorAlertDialogContentContainer,
  EditorAlertDialogTitle,
} from "@/components/popup/alert-dialog";
import { useToast } from "@/components/popup/message-toast";
import { SelectOptionItem } from "@/components/utils/select-options";
import { editorContextStore } from "@/contexts/editor-context";
import { PublicUserId } from "@/core/common/types/public-user-id";
import { AppRoleType, appRoleTypeToDisplayName } from "@/core/common/types/user-roles";
import { classNames } from "@/core/utils/classname-utils";
import { getCurrentTeamId } from "@/core/utils/team-utils";
import { doesUserHaveTeamFullPermission } from "@/core/utils/user-role-utils";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { ChevronDown } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UserProfileIcon } from "./user-profile-icon";

export type SelectUserRoleOptions = Record<string, SelectOptionItem<AppRoleType>[]>;

const defaultSelectUserRoleOptions: SelectUserRoleOptions = {
  Roles: [
    {
      name: appRoleTypeToDisplayName[AppRoleType.Writer],
      value: AppRoleType.Writer,
    },
    // {
    //   name: appRoleTypeToDisplayName[AppRoleType.Reader],
    //   value: AppRoleType.Reader,
    // },
  ],
};
export function SelectUserRole({
  role,
  setRole,
}: {
  role: AppRoleType;
  setRole: (value: AppRoleType) => void;
}) {
  if (role === AppRoleType.Owner) {
    return (
      <div
        className={classNames(
          "text-sm p-1 rounded flex flex-row w-[120px] items-center gap-1 text-zinc-400 truncate",
        )}
      >
        {appRoleTypeToDisplayName[role]}
      </div>
    );
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="right-2 flex flex-row items-center justify-between gap-1 cursor-pointer w-[120px]">
        <span className="text-sm text-zinc-400 hover:text-zinc-300 transition-colors">
          {appRoleTypeToDisplayName[role]}
        </span>
        <ChevronDown size={16} className="text-zinc-500 hover:text-zinc-300 transition-colors" />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={classNames(
            DropdownClassName,
            "min-w-[100px] bg-zinc-900 shadow-md border border-zinc-800 rounded-md text-zinc-300 text-sm",
          )}
          style={{
            zIndex: MessageDialogZIndex,
          }}
          sideOffset={5}
        >
          <DropdownMenu.Item
            key={AppRoleType.Writer}
            className={classNames(DropdownMenuItemClassName, " truncate")}
            onClick={() => {
              setRole(AppRoleType.Writer);
            }}
          >
            {appRoleTypeToDisplayName[AppRoleType.Writer]}
          </DropdownMenu.Item>
          <DropdownMenu.Item
            key={AppRoleType.Reader}
            className={classNames(DropdownMenuItemClassName, " truncate")}
            onClick={() => {
              setRole(AppRoleType.Reader);
            }}
          >
            {appRoleTypeToDisplayName[AppRoleType.Reader]}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

function SelectUserRoleLoading({
  targetRole,
  className = "",
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  targetRole: AppRoleType;
}) {
  return (
    <div
      {...props}
      className={classNames(
        "text-sm p-1 w-[120px] rounded flex flex-row items-center gap-1 text-zinc-500 transition-colors cursor-wait",
        className,
      )}
    >
      <SimpleSpinner width={14} height={14} pathClassName="fill-lime-500" />
      <span className="truncate">Updating...</span>
    </div>
  );
}

function RemoveUserFromTeamButton({ publicUserId }: { publicUserId: PublicUserId }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const backend = editorContextStore((state) => state.backend);
  const userTeams = editorContextStore((state) => state.userTeams);
  const currentTeamId = getCurrentTeamId();
  const { showToast } = useToast();

  const handleRemoveUser = React.useCallback(async () => {
    if (!backend || !userTeams) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await backend.removeUserFromTeam({
        publicTeamId: currentTeamId,
        publicUserId,
      });
      if (response.status === "success") {
        showToast({
          type: "info",
          message: "User removed from team.",
        });
      }
    } catch (error) {
      showToast({
        type: "error",
        message: "Failed to remove user from team.",
      });
    } finally {
      setIsLoading(false);
    }
  }, [backend, userTeams, currentTeamId, publicUserId, showToast]);

  if (isLoading) {
    return (
      <button
        className={classNames(
          SecondaryButtonClassName,
          "w-[100px] justify-center border-red-500 text-red-500 text-sm bg-red-500/10 shadow-red-500/20 hover:text-red-500 active:text-red-600 transition-colors cursor-wait",
        )}
        disabled
      >
        <div className="flex items-center gap-1">
          <SimpleSpinner width={14} height={14} pathClassName="fill-red-500" />
          <span className="truncate">Removing...</span>
        </div>
      </button>
    );
  }

  return (
    <EditorAlertDialog
      triggerChildren={
        <button
          className={classNames(
            SecondaryButtonClassName,
            "w-[100px] justify-center border-red-500 text-red-500 text-sm bg-red-500/10 shadow-red-500/20 hover:text-red-500 active:text-red-600 transition-colors",
          )}
        >
          Remove User
        </button>
      }
      contentChildren={
        <EditorAlertDialogContentContainer>
          <EditorAlertDialogTitle className="text-lg mb-2">Remove User</EditorAlertDialogTitle>
          <div className="text-sm text-zinc-400 mb-4">
            Are you sure you want to remove this user from the team? They'll need to be invited back
            to rejoin.
          </div>
          <div className="flex justify-end gap-3">
            <EditorAlertDialogCancelButton>Cancel</EditorAlertDialogCancelButton>
            <EditorAlertDialogActionButton
              onClick={handleRemoveUser}
              className={isLoading ? "cursor-wait" : "cursor-pointer"}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center gap-1">
                  <SimpleSpinner width={14} height={14} pathClassName="fill-red-500" />
                  <span className="truncate">Removing...</span>
                </div>
              ) : (
                "Remove user"
              )}
            </EditorAlertDialogActionButton>
          </div>
        </EditorAlertDialogContentContainer>
      }
    />
  );
}

function LeaveTeamButton() {
  const [isLoading, setIsLoading] = React.useState(false);
  const backend = editorContextStore((state) => state.backend);
  const userTeams = editorContextStore((state) => state.userTeams);
  const currentTeamId = getCurrentTeamId();
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const navigate = useNavigate();

  return (
    <EditorAlertDialog
      triggerChildren={
        <button
          className={classNames(SecondaryButtonClassName, "w-[100px] justify-center text-sm")}
        >
          Leave Team
        </button>
      }
      contentChildren={
        <EditorAlertDialogContentContainer>
          <EditorAlertDialogTitle className="text-lg mb-2">Leave Team</EditorAlertDialogTitle>
          <div className="text-sm text-zinc-400 mb-4">
            Are you sure you want to leave this team? You'll need to be invited back to rejoin.
          </div>
          <div className="flex justify-end gap-3">
            <EditorAlertDialogCancelButton>Cancel</EditorAlertDialogCancelButton>
            <EditorAlertDialogActionButton
              onClick={async () => {
                if (!backend || !userTeams) {
                  return;
                }
                setIsLoading(true);
                try {
                  await backend.removeUserFromTeam({
                    publicTeamId: currentTeamId,
                    publicUserId,
                  });
                  navigate("/");
                } finally {
                  setIsLoading(false);
                }
              }}
              className={isLoading ? "cursor-wait" : "cursor-pointer"}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center gap-1">
                  <SimpleSpinner width={14} height={14} pathClassName="fill-red-500" />
                  <span className="truncate">Leaving...</span>
                </div>
              ) : (
                "Leave team"
              )}
            </EditorAlertDialogActionButton>
          </div>
        </EditorAlertDialogContentContainer>
      }
    />
  );
}
enum UserRoleEditorStatus {
  Ready = "Ready",
  Loading = "Loading",
}

export function UserRoleRowItem({
  userMetadata,
  userCanEdit,
  setUserRole,
  className = "",
  selectUserRoleOptions = defaultSelectUserRoleOptions,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  userMetadata: PublicUserMetadataDictItem;
  userCanEdit: boolean;
  setUserRole: (role: AppRoleType) => Promise<void>;
  selectUserRoleOptions?: SelectUserRoleOptions;
}) {
  const [status, setStatus] = React.useState(UserRoleEditorStatus.Ready);
  const [targetRole, setTargetRole] = React.useState(AppRoleType.Reader);
  const backend = editorContextStore((state) => state.backend);
  const displayName = React.useMemo(() => userMetadata.name || "Anon", [userMetadata.name]);
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const userIsOwner = editorContextStore(doesUserHaveTeamFullPermission);

  return (
    <div
      className={classNames("flex flex-row items-center gap-4 text-base sm:text-sm", className)}
      {...props}
    >
      <div className="min-w-0 hidden sm:block">
        <UserProfileIcon backend={backend} name={displayName} publicUserId={userMetadata.id} />
      </div>
      <div className="flex flex-col gap-2 sm:flex-row sm:gap-0 min-w-0 w-full items-start sm:items-center">
        <div
          className={classNames(
            "flex flex-col sm:flex-row w-[30vw] truncate text-sm text-zinc-300 gap-1",
          )}
        >
          <div>{displayName}</div>
          {publicUserId === userMetadata.id ? (
            <div className="text-zinc-400 truncate">(You)</div>
          ) : null}
        </div>
        <div className="sm:ml-auto sm:min-w-0">
          {/* Show leave team button if current user is not an owner and this row item is the current user */}
          {/* TODO: Add this back in once backend is updated to remove user from team */}
          {/* publicUserId === userMetadata.id && !userIsOwner ? (
                        <LeaveTeamButton />
                    ) : */}
          {/* Show remove user button if current user is owner and this row item is a different user */}
          {userIsOwner && publicUserId !== userMetadata.id ? (
            <RemoveUserFromTeamButton publicUserId={userMetadata.id} />
          ) : null}
        </div>
      </div>
      <div className="min-w-0 text-left ml-auto" />
      <div className="ml-auto sm:ml-0">
        {status === UserRoleEditorStatus.Loading ? (
          <SelectUserRoleLoading targetRole={targetRole} />
        ) : userCanEdit && publicUserId !== userMetadata.id ? (
          <div className="text-sm p-1 rounded flex flex-row text-left w-[120px] gap-1 text-zinc-400 truncate">
            {appRoleTypeToDisplayName[userMetadata.role]}
          </div>
        ) : (
          // <SelectUserRole
          //   role={userMetadata.role}
          //   setRole={(role) => {
          //     setStatus(UserRoleEditorStatus.Loading);
          //     setTargetRole(role);
          //     setUserRole(role)
          //       .then((response) => {
          //         debugLog(response);
          //       })
          //       .finally(() => {
          //         setStatus(UserRoleEditorStatus.Ready);
          //       });
          //   }}
          // />
          <div className="text-sm p-1 rounded flex flex-row text-left w-[120px] gap-1 text-zinc-400 truncate">
            {appRoleTypeToDisplayName[userMetadata.role] || "None"}
          </div>
        )}
      </div>
    </div>
  );
}
