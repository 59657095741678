import { CustomModelPostProcessActionType } from "@/backend/custom-model-post-process";
import { displayUiMessage } from "@/components/utils/display-message";
import { ImageComponent } from "@/components/utils/image";
import { editorContextStore } from "@/contexts/editor-context";
import {
  CustomModelPredictionItem,
  getImageSizeFromCustomModelPredictionInput,
  isCustomModelTrainingStatusActive,
  UserAssetType,
} from "@/core/common/types";
import {
  AssetMetadata,
  isAssetMetadata,
  UserAssetDocUploadStatus,
} from "@/core/common/types/assetV2";
import { DocVisibility } from "@/core/common/types/doc-visibility";
import { Assets } from "@/core/controllers/assets";
import { classNames } from "@/core/utils/classname-utils";
import { getImageUrlFromImageId, isImageFile } from "@/core/utils/image-utils";
import { debugError } from "@/core/utils/print-utilts";
import { getCurrentTeamId } from "@/core/utils/team-utils";
import { sortByTimeModified } from "@/core/utils/time-utils";
import { generateUUID } from "@/core/utils/uuid-utils";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import {
  PrimaryButtonClassName,
  PrimaryButtonClassNameDisabled,
  PrimaryButtonClassNameLoading,
  SecondaryButtonClassName,
  SecondaryButtonClassNameDisabled,
  SecondaryButtonClassNameInactive,
} from "components/constants/class-names";
import { SimpleSpinner } from "components/icons/simple-spinner";
import { ScrollAreaContainer } from "components/scroll-area/scroll-area";
import { UploadCloud } from "lucide-react";
import React from "react";
import { PanelSwitchRoot } from "../panels/panel-items/components/switch";
import { Tooltip } from "../utils/tooltip";
import {
  imageEditorContainerClassName,
  mainPanelClassName,
  toolbarPanelClassName,
} from "./classnames";
import { CustomModelImageEditorBackButton } from "./custom-model-editor-back-button";
import {
  CustomModelImageEditorMode,
  loadImageFromCustomModelPredictionOutput,
  useCustomModelImageEditorContext,
} from "./custom-model-image-editor-context";
import { useCustomModelCompareOutputContext } from "./custom-model-image-output-context";
import {
  CustomModelVirtualTryOnStatus,
  useCustomModelVirtualTryOnContext,
} from "./custom-model-virtual-tryon-context";

enum UploadClothButtonStatus {
  Idle = "Idle",
  Loading = "Loading",
}

function getImageFilesFromFileList(fileList: FileList) {
  const outputFiles: File[] = [];

  for (let i = 0; i < fileList.length; ++i) {
    const file = fileList[i];
    if (!file) {
      continue;
    }

    if (isImageFile(file)) {
      outputFiles.push(file);
    }
  }

  return outputFiles;
}

function UploadClothButton() {
  const backend = editorContextStore((state) => state.backend);
  const currentTeamId = getCurrentTeamId();
  const tryonUploadId = React.useMemo(() => `tryon-upload-${generateUUID()}`, []);
  const [status, setStatus] = React.useState(UploadClothButtonStatus.Idle);
  const { clothImageStoragePath, setClothImageStoragePath } = useCustomModelVirtualTryOnContext();

  const uploadFiles = React.useCallback(
    async (newFiles: FileList) => {
      if (!backend) {
        return;
      }

      if (status !== UploadClothButtonStatus.Idle) {
        return;
      }

      try {
        setStatus(UploadClothButtonStatus.Loading);

        const imageFiles = getImageFilesFromFileList(newFiles);

        const storagePaths = await Promise.all(
          imageFiles.map(async (file) => {
            const storagePath = await backend.uploadCustomModelVirtualTryOnInputToStorage({
              data: file,
              publicTeamId: currentTeamId,
            });

            if (!storagePath) {
              return;
            }

            return storagePath;
          }),
        );

        setClothImageStoragePath(storagePaths[0]);
      } catch (error) {
        debugError("Error uploading virtual try-on input image: ", error);
      } finally {
        setStatus(UploadClothButtonStatus.Idle);
      }
    },
    [backend, currentTeamId, status, setStatus, setClothImageStoragePath],
  );

  return (
    <>
      <input
        type="file"
        id={tryonUploadId}
        style={{
          display: "none",
        }}
        onChange={(e) => {
          const files = e.target?.files;

          if (!files) {
            return;
          }

          uploadFiles(files);
        }}
      />
      <label htmlFor={tryonUploadId}>
        <div
          id="left-panel-assets-upload-image-button"
          className={classNames(
            "flex items-center justify-center cursor-pointer transition-colors",
            status === UploadClothButtonStatus.Loading
              ? PrimaryButtonClassNameLoading
              : clothImageStoragePath
                ? SecondaryButtonClassNameInactive
                : PrimaryButtonClassName,
          )}
        >
          {status === UploadClothButtonStatus.Loading ? (
            <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
          ) : (
            <UploadCloud className="select-none" size={18} />
          )}
          <div className="ml-2 select-none">Upload clothing</div>
        </div>
      </label>
    </>
  );
}

function UpscaleToggle() {
  const { runUpscale, setRunUpscale } = useCustomModelVirtualTryOnContext();
  return (
    <div className="flex items-center gap-2 pr-2">
      <Tooltip
        triggerProps={{
          className: "flex-1",
        }}
        triggerChildren={
          <div className="mr-0 lg:min-w-[8rem] group flex flex-row items-center gap-2">
            <span className="min-w-[50px] text-left text-sm font-semibold">Upscale result</span>
            <QuestionMarkCircledIcon className="text-zinc-500 group-hover:text-zinc-300 transition-colors" />
          </div>
        }
        contentChildren={
          <div className="flex flex-col gap-2">
            <div>
              Upscale the result to produce high quality image (but might change the details of the
              clothing).
            </div>
          </div>
        }
      />
      <PanelSwitchRoot checked={runUpscale} onCheckedChange={setRunUpscale} />
    </div>
  );
}

function GenerateCloth() {
  const backend = editorContextStore((state) => state.backend);
  const storageManager = editorContextStore((state) => state.storageManager);
  const { promptEditorState, status, setStatus, clothImageStoragePath, runUpscale } =
    useCustomModelVirtualTryOnContext();

  const { setSourceImageUrl, setOutputImageUrls, setActiveOutputImageIndex, setWidth, setHeight } =
    useCustomModelCompareOutputContext();

  const { prediction, imageIndex, imageUrl, setModes } = useCustomModelImageEditorContext();

  const handleClick = React.useCallback(async () => {
    if (!backend || !storageManager || !clothImageStoragePath) {
      return;
    }

    if (status !== CustomModelVirtualTryOnStatus.Idle) {
      return;
    }

    setStatus(CustomModelVirtualTryOnStatus.Loading);

    try {
      const output = prediction?.output;

      if (!output || output.length <= 0) {
        return;
      }

      const index = imageIndex % output.length;

      const humanImageStoragePath = output[index];

      if (!humanImageStoragePath) {
        return;
      }

      const sourcePredictionId = prediction?.id;

      if (!sourcePredictionId) {
        return;
      }

      const response = await backend.customModelVirtualTryOn({
        type: CustomModelPostProcessActionType.TryOn,
        clothImageUrl: clothImageStoragePath,
        personImageUrl: humanImageStoragePath,
        prompt: promptEditorState.text,
        promptJson: promptEditorState.json,
        customModelScaleConfigs: promptEditorState.scaleConfigs,
        runPostprocessUpscale: runUpscale,
      });

      if (!response.ok) {
        displayUiMessage(response.message, "error");
        return;
      }

      const predictionId = response.predictionId;

      const { width, height } = getImageSizeFromCustomModelPredictionInput(prediction.input);

      const customModelPrediction = await new Promise<CustomModelPredictionItem>((resolve) => {
        backend.onCustomModelPredictionUpdate({
          predictionId,
          callback: (customModelPrediction) => {
            if (!customModelPrediction) {
              return;
            }

            if (!isCustomModelTrainingStatusActive(customModelPrediction.status)) {
              resolve(customModelPrediction);
            }
          },
        });
      });

      const outputStoragePath = customModelPrediction.output?.[0];

      if (!outputStoragePath) {
        return;
      }

      const outputImageUrl = await Assets.loadAssetFromPath({
        storageManager,
        path: outputStoragePath,
      });

      if (!outputImageUrl) {
        return;
      }

      setWidth(width);
      setHeight(height);
      setSourceImageUrl(imageUrl);
      setOutputImageUrls([outputImageUrl]);
      setActiveOutputImageIndex(0);
      setModes((modes) => [...modes, CustomModelImageEditorMode.CompareOutputs]);
    } catch (error) {
      debugError("Error custom model virtual try on status ", error);
    } finally {
      setStatus(CustomModelVirtualTryOnStatus.Idle);
    }
  }, [
    backend,
    storageManager,
    prediction,
    imageIndex,
    imageUrl,
    clothImageStoragePath,
    runUpscale,
    setWidth,
    setHeight,
    setSourceImageUrl,
    setOutputImageUrls,
    setActiveOutputImageIndex,
    status,
    setStatus,
    setModes,
    promptEditorState,
  ]);

  return (
    <button
      className={classNames(
        status === CustomModelVirtualTryOnStatus.Loading
          ? PrimaryButtonClassNameLoading
          : !backend || !clothImageStoragePath
            ? PrimaryButtonClassNameDisabled
            : clothImageStoragePath
              ? PrimaryButtonClassName
              : SecondaryButtonClassNameDisabled,
        "flex flex-row items-center justify-center gap-2",
      )}
      onClick={() => {
        handleClick();
      }}
    >
      {status === CustomModelVirtualTryOnStatus.Loading && (
        <SimpleSpinner width={18} height={18} pathClassName="fill-lime-500" />
      )}
      <span>Replace clothing</span>
    </button>
  );
}

function ClothImageAssetGridItem({ assetMetadata }: { assetMetadata: AssetMetadata }) {
  const storageManager = editorContextStore((state) => state.storageManager);

  const [imageUrl, setImageUrl] = React.useState("");

  const { clothImageStoragePath, setClothImageStoragePath } = useCustomModelVirtualTryOnContext();

  const thumbnailStoragePath = React.useMemo(() => {
    return (
      assetMetadata.thumbnail256StoragePath ||
      assetMetadata.thumbnail128StoragePath ||
      assetMetadata.thumbnail512StoragePath ||
      assetMetadata.storagePath
    );
  }, [assetMetadata]);

  const storagePath = React.useMemo(() => assetMetadata.storagePath, [assetMetadata]);

  React.useEffect(() => {
    if (!storageManager) {
      debugError("Storage manager is invalid.");
      return;
    }

    Assets.loadAssetFromPath({
      storageManager,
      path: thumbnailStoragePath,
    }).then((imageUrl) => {
      setImageUrl(imageUrl ?? "");
    });
  }, [storageManager, thumbnailStoragePath]);

  return (
    <div
      className={classNames(
        clothImageStoragePath === storagePath
          ? SecondaryButtonClassName
          : SecondaryButtonClassNameInactive,
        "p-0 rounded-md overflow-hidden flex items-center justify-center",
      )}
      onClick={() => {
        setClothImageStoragePath(storagePath);
      }}
    >
      <ImageComponent src={imageUrl} className="w-full h-full object-contain" />
    </div>
  );
}

function getAssetMetadataFromImages(imageId: string): AssetMetadata {
  return {
    id: imageId,
    assetType: UserAssetType.TryOnClothImage,
    isDeleted: false,
    storagePath: getImageUrlFromImageId({
      imageId,
      imageSize: "public",
    }),
    thumbnail128StoragePath: getImageUrlFromImageId({
      imageId,
      imageSize: "128",
    }),
    thumbnail256StoragePath: getImageUrlFromImageId({
      imageId,
      imageSize: "256",
    }),
    uploadStatus: UserAssetDocUploadStatus.Ready,
    roles: {},
    visibility: DocVisibility.Public,
  };
}

const clothImageAssets: AssetMetadata[] = [
  "sample-cloth-0",
  "sample-cloth-1",
  "sample-cloth-3",
  "sample-cloth-5",
].map(getAssetMetadataFromImages);

function ClothImageAssetGrid() {
  const backend = editorContextStore((state) => state.backend);
  const publicUserId = editorContextStore((state) => state.publicUserId);
  const currentTeamId = getCurrentTeamId();
  const [assets, setAssets] = React.useState<Record<string, AssetMetadata>>({});
  const { setClothImageStoragePath } = useCustomModelVirtualTryOnContext();

  const assetGenerator = React.useMemo(() => {
    if (!backend || !publicUserId) {
      return undefined;
    }

    return backend.getAssetMetadataGenerator({
      publicUserId,
      publicTeamId: currentTeamId,
      assetType: UserAssetType.TryOnClothImage,
    });
  }, [backend, publicUserId, currentTeamId]);

  React.useEffect(() => {
    setAssets({});

    assetGenerator?.getNextBatch().then((assets) => {
      const assetsMetadata = Object.fromEntries(
        assets.filter(isAssetMetadata).map((asset) => [asset.id, asset]),
      );

      setAssets((prevAssets) => ({
        ...prevAssets,
        ...assetsMetadata,
      }));

      const storagePath = Object.values(assetsMetadata)?.[0]?.storagePath;

      if (storagePath) {
        setClothImageStoragePath(storagePath);
      }
    });
  }, [assetGenerator, setClothImageStoragePath]);

  const assetsSorted = React.useMemo(
    () => [...Object.values(assets).sort(sortByTimeModified), ...clothImageAssets],
    [assets],
  );

  return (
    <div className="grid grid-cols-2 gap-2">
      {assetsSorted.map((asset) => (
        <ClothImageAssetGridItem key={asset.id} assetMetadata={asset} />
      ))}
    </div>
  );
}

function ClothImageComponent({
  className = "",
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement>) {
  const [imageUrl, setImageUrl] = React.useState("");
  const storageManager = editorContextStore((state) => state.storageManager);

  const { clothImageStoragePath } = useCustomModelVirtualTryOnContext();

  React.useEffect(() => {
    if (!storageManager || !clothImageStoragePath) {
      return;
    }

    Assets.loadAssetFromPath({
      storageManager,
      path: clothImageStoragePath,
    }).then((imageUrl) => {
      setImageUrl(imageUrl ?? "");
    });
  }, [storageManager, clothImageStoragePath]);

  return <ImageComponent {...props} src={imageUrl} className={className} />;
}

export function CustomModelVirtualTryOn() {
  const storageManager = editorContextStore((state) => state.storageManager);
  const { prediction, imageUrl, setImageUrl } = useCustomModelImageEditorContext();

  return (
    <div className={imageEditorContainerClassName}>
      <div className={classNames(mainPanelClassName, "flex-1")}>
        <div className="w-full h-full flex flex-col lg:flex-row bg-zinc-800/50 rounded-md overflow-hidden divide-y lg:divide-x divide-zinc-800">
          <div className="w-full h-[50%] lg:w-[50%] lg:h-full">
            <ClothImageComponent className="object-contain w-full h-full" />
          </div>
          <div className="w-full h-[50%] lg:w-[50%] lg:h-full">
            <ImageComponent
              src={imageUrl}
              className="object-contain w-full h-full"
              onError={(e) => {
                debugError(`Error loading image from url ${imageUrl}: `, e);

                loadImageFromCustomModelPredictionOutput({
                  storageManager,
                  prediction,
                }).then((url) => {
                  setImageUrl(url ?? "");
                });
              }}
            />
          </div>
        </div>
      </div>
      <ScrollAreaContainer className={classNames(toolbarPanelClassName)}>
        <div className="h-fit flex flex-col items-stretch gap-2">
          <CustomModelImageEditorBackButton />
          <div className="flex flex-col items-stretch gap-2">
            <UpscaleToggle />
            <GenerateCloth />
            <UploadClothButton />
          </div>
          <div className="flex flex-col items-stretch gap-2">
            <ClothImageAssetGrid />
          </div>
        </div>
      </ScrollAreaContainer>
    </div>
  );
}
