import { debugLog } from "@/core/utils/print-utilts";
import { editorContextStore } from "contexts/editor-context";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

interface RequireAuthProps {
  children: React.ReactNode;
}

export function RequireAuth({ children }: RequireAuthProps) {
  const user = editorContextStore((state) => state.user);
  const location = useLocation();

  if (user?.uid) {
    return <>{children}</>;
  } else {
    // Preserve the current pathname and search parameters
    const loginPath = `/login?redirectTo=${encodeURIComponent(location.pathname + location.search)}`;
    debugLog("Login path: ", loginPath);

    return <Navigate replace to={loginPath} />;
  }
}
