import { editorContextStore } from "@/contexts/editor-context";
import { AppRoleType } from "@/core/common/types/user-roles";
import { debugLog } from "@/core/utils/print-utilts";
import { getTeamUserRole } from "@/core/utils/user-role-utils";
import { useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export function ProtectedRoute({ roles }: { roles: string[] }) {
  const user = editorContextStore((state) => state.user);
  const userRole = getTeamUserRole(editorContextStore((state) => state));
  const [currentRole, setCurrentRole] = useState<AppRoleType | undefined>(userRole);
  const isUpdating = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (userRole !== currentRole) {
      isUpdating.current = true;
      setCurrentRole(userRole);
    } else {
      isUpdating.current = false;
    }
  }, [userRole, currentRole]);

  if (user?.uid && userRole) {
    if (roles.includes(currentRole)) {
      return <Outlet />;
    } else {
      return <Navigate to="/" replace />;
    }
  }

  const loginPath = `/login?redirectTo=${encodeURIComponent(location.pathname + location.search)}`;
  debugLog("Protected route redirect to: ", loginPath);
  return <Navigate replace to={loginPath} />;
}
