import { FullScreenInviteCodeInput } from "@/components-mobile/auth/invite-code";
import { LoginFullscreen } from "@/components-mobile/auth/login-fullscreen";
import {
  MobileRedirectErrorFullscreen,
  MobileRedirectFullscreen,
} from "@/components/auth/require-desktop";
import { NotFound } from "@/components/not-found";
import { FinishEmailLogin } from "components-mobile/auth/finish-email-login";
import {
  API,
  ASSETS,
  CUSTOM_MODELS,
  EDITOR_DEBUG,
  EMAIL_LINK_SIGNIN,
  INVITE_CODE,
  LOGIN,
  MOBILE,
  MOBILE_ERROR,
  NEW_CUSTOM_MODEL,
  NEW_PROJECT,
  NEW_TRYON_PROJECT,
  PROJECTS,
  TEMPLATES,
  VIDEOS,
} from "components-mobile/constants/routes";
import { ApiPlaygroundRouter } from "components-mobile/dashboard/api";
import { CreateNewProject } from "components-mobile/dashboard/create-new-project";
import { CreateNewTryOnProject } from "components-mobile/dashboard/create-new-tryon-project";
import { Dashboard } from "components-mobile/dashboard/dashboard";
import { EditorRouter } from "components-mobile/editor/editor-router";
import { Route, Routes } from "react-router-dom";
import { EditorInternal } from "./editor/canvas-editor";

export function MobileAppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard dashboardType="projects" />} />
      <Route path={`/${LOGIN}`} element={<LoginFullscreen />} />
      <Route path={`/${INVITE_CODE}`} element={<FullScreenInviteCodeInput />} />
      <Route path={`/${EMAIL_LINK_SIGNIN}`} element={<FinishEmailLogin />} />
      <Route path={`/${MOBILE}`} element={<MobileRedirectFullscreen />} />
      <Route path={`/${MOBILE_ERROR}`} element={<MobileRedirectErrorFullscreen />} />
      <Route path={`/${PROJECTS}`} element={<Dashboard dashboardType="projects" />} />
      <Route path={`/${PROJECTS}/:projectId`} element={<EditorRouter />} />
      <Route path={`/${API}`} element={<Dashboard dashboardType="manage-api" />} />
      <Route path={`/${API}/:apiId`} element={<ApiPlaygroundRouter />} />
      <Route path={`/${EDITOR_DEBUG}`} element={<EditorInternal />} />
      <Route path={`/${NEW_PROJECT}`} element={<CreateNewProject />} />
      <Route path={`/${NEW_TRYON_PROJECT}`} element={<CreateNewTryOnProject />} />
      <Route
        path={`/${TEMPLATES}`}
        element={<MobileRedirectFullscreen destination="templates" />}
      />
      <Route
        path={`/${NEW_CUSTOM_MODEL}`}
        element={<MobileRedirectFullscreen destination="customModels" />}
      />
      <Route
        path={`/${CUSTOM_MODELS}`}
        element={<MobileRedirectFullscreen destination="customModels" />}
      />
      <Route path={`/${VIDEOS}`} element={<MobileRedirectFullscreen destination="video" />} />
      <Route path={`/${ASSETS}`} element={<MobileRedirectFullscreen destination="assets" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
