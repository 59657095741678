import { AnalyticsConfig } from "@/analytics/config";
import { editorContextStore } from "@/contexts/editor-context";
import { classNames } from "@/core/utils/classname-utils";
import { preprocessImageUrl } from "@/core/utils/url-utils";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import {
  getProjectDashboardCarouselTemplates,
  Template,
} from "components/constants/user-project-type-styles";
import { startTemplate } from "components/dashboard/dashboard-templates";
import { GridCarousel } from "components/utils/grid-carousel";
import { Tooltip } from "components/utils/tooltip";
import { HTMLAttributes, RefAttributes } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

export type TemplateAction =
  | "WorkflowCarouselItemClick"
  | "WorkflowDashboardCardClick"
  | "ProjectDashboardCardClick"
  | "SidebarNavClick";

export type TemplateActionData = {
  template?: string;
};
export function trackTemplateAction(action: TemplateAction, data: TemplateActionData = {}): void {
  const { analytics } = editorContextStore.getState();

  analytics.track(AnalyticsConfig.WorkflowAction, {
    action,
    ...data,
  });
}

type TemplateCarouselItemProps = AspectRatio.AspectRatioProps &
  RefAttributes<HTMLDivElement> & {
    name: string;
    url: string;
    template: Template;
    onClick?: () => void;
  };
function TemplateCarouselItem({
  name,
  url,
  template,
  children,
  onClick = () => {},
  ...props
}: TemplateCarouselItemProps) {
  return (
    <div className="">
      <AspectRatio.Root
        ratio={1.25}
        draggable
        className={classNames("relative cursor-pointer items-center justify-center")}
        onClick={onClick}
        onDragEnd={(event) => {}}
        {...props}
      >
        <div
          className="absolute pointer-events-none select-none inset-0 transition-all duration-300 bg-cover bg-center"
          style={{
            backgroundImage: `url(${preprocessImageUrl(url)})`,
          }}
        ></div>
        <div className="absolute bottom-0 bg-gradient-to-t from-black to-transparent transition-all group-hover:opacity-0 group-hover:pl-10 group-hover:from-transparent duration-300 w-full text-zinc-300 text-sm font-bold pt-8 pb-2 pl-2 truncate text-left">
          {name}
        </div>
        {children}
      </AspectRatio.Root>
    </div>
  );
}

function getTemplateCarouselItems(navigate: NavigateFunction) {
  return getProjectDashboardCarouselTemplates().map((template, index) => {
    return (
      <div
        key={index}
        className="snap-center hover:border-lime-600 border-zinc-800 transition-colors border-2 rounded-md overflow-hidden"
      >
        <Tooltip
          triggerProps={{
            asChild: false,
          }}
          triggerChildren={
            <TemplateCarouselItem
              template={template}
              name={template.name}
              url={template.image}
              onClick={() => {
                startTemplate(template.type, navigate, {
                  ...(template.data || {}),
                });
                trackTemplateAction("WorkflowCarouselItemClick", {
                  template: template.name,
                });
              }}
            />
          }
          contentProps={{
            align: "center",
            sideOffset: 8,
          }}
          contentChildren={template.tooltipText}
        />
      </div>
    );
  });
}

export type TemplateCarouselProps = HTMLAttributes<HTMLDivElement>;
export function TemplateCarousel({ children, className, ...props }: TemplateCarouselProps) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row w-full">
      <GridCarousel
        columnsPerPage={6}
        itemsPerScroll={1}
        className="mb-5"
        childrenContainerClassName="gap-4"
      >
        {getTemplateCarouselItems(navigate)}
      </GridCarousel>
    </div>
  );
}
